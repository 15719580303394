// @flow
import { type Saga } from 'redux-saga';
import { asset_groups } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call } from 'redux-saga/effects';

import {
  withCache,
  handleNormalizedResponse,
} from '../../resource_fetch/sagas';

export const getAssetGroupMemberships = withCache<string>(
  assetGroupId => `asset_group_${assetGroupId}_memberships`,
  function*(assetGroupId: string): Saga<void> {
    const response = yield* callPromise(
      asset_groups.memberships.list,
      assetGroupId,
    );

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
