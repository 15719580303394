// @flow
import { createAction } from 'redux-actions';
import { type EventsList } from '@dt/horizon-api';

export const eventsReceived = createAction<
  'events/received',
  [EventsList],
  EventsList,
>('events/received', events => events);

export const activityDashboardMounted = createAction<
  'mounted/events/dashboard',
  [],
  { key: 'activity_dashboard', ... },
>('mounted/events/dashboard', () => ({
  key: 'activity_dashboard',
}));
