// @flow
import immer from 'immer';
import type { RestfulAPI } from '@dt/horizon-api';
import type { ActionType } from 'redux-actions';
import {
  restfulAPIsReceived,
  restfulAPIsReceivedIdsForAssetSearch,
} from './actions';

type Actions =
  | ActionType<typeof restfulAPIsReceived>
  | ActionType<typeof restfulAPIsReceivedIdsForAssetSearch>;

export type RestfulAPIsState = {|
  +id: { [string]: void | RestfulAPI, ... },
  +for_operation_id: { [string]: void | string, ... },
  +for_network_service_id: { [string]: void | Array<string>, ... },
  +for_openapi_definition_id: { [string]: void | Array<string>, ... },
  +for_discovered_via_aws_authenticator_id: {
    [string]: void | Array<string>,
    ...,
  },
  +for_policy_violation_id: { [string]: void | string, ... },
  +for_hosted_on: { [string]: Array<string>, ... },
  +for_search_id: { [string]: Array<string>, ... },
|};

const initialState = {
  id: {},
  for_operation_id: {},
  for_network_service_id: {},
  for_openapi_definition_id: {},
  for_discovered_via_aws_authenticator_id: {},
  for_policy_violation_id: {},
  for_hosted_on: {},
  for_search_id: {},
};

export default immer<RestfulAPIsState, Actions>((draft, action: Actions) => {
  if (action.type === restfulAPIsReceived.toString()) {
    const network_services_by_id = action.payload.network_services.reduce(
      (current, value) => {
        current[value.id] = value;
        return current;
      },
      {},
    );
    for (const item of action.payload.restful_apis) {
      const {
        id,
        openapi_definition_id,
        network_service_id,
        api_operations,
        discovered_via_aws_authenticator_id,
      } = item;
      draft.id[id] = item;

      let hosted_on = network_services_by_id[network_service_id]?.hosted_on;

      const for_network =
        draft.for_network_service_id[network_service_id] || [];
      const for_definition =
        draft.for_openapi_definition_id[openapi_definition_id] || [];

      if (!for_network.includes(id)) {
        for_network.push(id);
      }

      if (!for_definition.includes(id)) {
        for_definition.push(id);
      }

      for (const operation of api_operations) {
        draft.for_operation_id[operation.id] = id;
        for (const policy_violation_id of operation.policy_violation_ids) {
          draft.for_policy_violation_id[policy_violation_id] = id;
        }
      }

      if (discovered_via_aws_authenticator_id) {
        const for_aws_authenticator =
          draft.for_discovered_via_aws_authenticator_id[
            discovered_via_aws_authenticator_id
          ] || [];
        if (!for_aws_authenticator.includes(id)) {
          for_aws_authenticator.push(id);
        }
      }

      if (hosted_on) {
        draft.for_hosted_on[hosted_on];
      }
    }
  }

  if (action.type === restfulAPIsReceivedIdsForAssetSearch.toString()) {
    const { searchId, ids } = action.payload;
    draft.for_search_id[searchId] = (
      draft.for_search_id[searchId] || []
    ).concat(ids);
  }
}, initialState);
