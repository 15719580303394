// @flow
import { HostedOnEnum } from '@dt/horizon-api';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';
import type { State } from '../store_state_type';
import type { GraphqlApi } from '@dt/horizon-api';
import type { GraphqlApiDecorated } from './types';
import type { PolicyViolationWithAffectedComponentList } from '../policy_violations/types';

export const graphql_apis = ({ graphql_apis }: State) => graphql_apis;

export const decorate = (
  graphql_api: GraphqlApi,
  policy_violations_decorated: PolicyViolationWithAffectedComponentList,
): ?GraphqlApiDecorated => {
  const related_policy_violations_decorated = policy_violations_decorated.filter(
    pv => graphql_api.policy_violation_ids.includes(pv.id),
  );

  return {
    ...graphql_api,
    asset_type: AssetTypeDict.RESTFUL_API,
    name: graphql_api.url,
    url: graphql_api.url,
    hosted_on: HostedOnEnum.UNKNOWN,
    policy_violations_decorated: [],
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue(
      {
        policy_violations_decorated: related_policy_violations_decorated,
      },
    ),
  };
};
