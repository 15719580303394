// @flow
import orderBy from 'lodash/orderBy';
import memoize from 'memoizee';

import { sortObjectsByDateProperty } from '@dt/date';
import type { SecurityTemplateSeverityEnum } from '@dt/enums/SecurityTemplateSeverityEnum';
import type { FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import { type FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';

import type { Target } from './types';
import getAge from './getAge';
import {
  isOpenStatus,
  getCalculatedStatus,
  sortTargets,
} from './targets/status';

type Finding = {
  +severity?: SecurityTemplateSeverityEnum,
  +targets: $ReadOnlyArray<Target>,
  +date_created?: string | Date, // Date ISO 8601: "2017-01-31T14:09:07.313240"
  +priority?: ?FindingPriorityEnum,
  +aggregated_status?: FindingTargetStatusEnum,
  ...
};

export type SortOrder = $ReadOnlyArray<{
  field: string,
  order?: 'asc' | 'desc',
  ...
}>;

const severityValues = { HIGH: 3, MEDIUM: 2, LOW: 1 };
const fieldSortMapping = {
  computed_aging: finding => getAge(finding),
  severity: ({ severity }) =>
    Object.keys(severityValues).includes(severity)
      ? severityValues[severity]
      : 4,
};

export function getSortFn<T: Finding>(
  sortOrder?: ?SortOrder,
): ($ReadOnlyArray<T>) => $ReadOnlyArray<T> {
  return findings => {
    if (!sortOrder) {
      return findings.slice().sort(sortFindings);
    }
    return orderBy(
      findings,
      sortOrder.map(({ field }) => finding =>
        Object.keys(fieldSortMapping).includes(field)
          ? fieldSortMapping[field](finding)
          : finding[field],
      ),
      sortOrder.map(({ order }) => order || 'asc'),
    );
  };
}

const getLatestTarget = memoize((finding: Finding) => {
  if (
    !finding ||
    !finding.targets ||
    typeof finding.targets.slice !== 'function'
  ) {
    return null;
  }
  return finding.targets.slice().sort(sortTargets)[0];
});

function sortFindings<T: Finding>(a: T, b: T): number {
  if (!a) {
    return 1;
  } else if (!b) {
    return -1;
  }

  const aStatus = getCalculatedStatus(a);
  const bStatus = getCalculatedStatus(b);

  if (!aStatus) {
    return 1;
  } else if (!bStatus) {
    return -1;
  }

  const aStatusIsOpen = isOpenStatus(aStatus);
  const bStatusIsOpen = isOpenStatus(bStatus);

  if (aStatusIsOpen === bStatusIsOpen && a.priority !== b.priority) {
    if (!a.priority) {
      return 1;
    }

    const aPriorityNumber = parseInt(a.priority.substring(1), 10);

    if (!b.priority) {
      return -1;
    }

    const bPriorityNumber = parseInt(b.priority.substring(1), 10);

    if (!isNaN(aPriorityNumber) && !isNaN(bPriorityNumber)) {
      return aPriorityNumber - bPriorityNumber;
    }

    if (isNaN(aPriorityNumber) && !isNaN(bPriorityNumber)) {
      return 1;
    }

    if (isNaN(bPriorityNumber) && !isNaN(aPriorityNumber)) {
      return -1;
    }
  }

  if (
    aStatusIsOpen === bStatusIsOpen &&
    typeof a.severity === 'string' &&
    typeof b.severity === 'string'
  ) {
    if (a.severity === b.severity) {
      if (!a.targets) {
        return 1;
      }

      const aTarget = getLatestTarget(a);

      if (!b.targets) {
        return -1;
      }

      const bTarget = getLatestTarget(b);

      return sortByDateCreated(aTarget, bTarget);
    }

    const SeverityRankings = {
      HIGH: 1,
      MEDIUM: 2,
      LOW: 3,
      _: 4,
      UNKNOWN: 5,
    };

    return (
      SeverityRankings[a.severity || '_'] - SeverityRankings[b.severity || '_']
    );
  }

  if (!aStatusIsOpen) {
    return 1;
  } else {
    return -1;
  }
}

export const SORT_DIRECTION = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
};

function sortByDateCreated(a, b) {
  return sortObjectsByDateProperty('date_created', a, b);
}
