//@flow
import { createSelector } from 'reselect';
import memoize from 'memoizee';
import SecurityFindingsFilterEnum from '@dt/enums/SecurityFindingsFilterEnum';
import groupBy from 'lodash/groupBy';
import type { State } from '../ReduxStateType';
import { getSortFn, SORT_DIRECTION } from '@dt/findings/sort';
import type { SortOrder } from '@dt/findings/sort';
import { search, type SearchParam } from '@dt/findings/search';
import { getComplianceSearchFilter } from '@dt/findings/findingsFilterCreator';
import type { LinkedAppIssueType, SecurityFinding } from '@dt/findings/types';
import findingsFilterCreator, {
  type Query,
} from '@dt/findings/findingsFilterCreator';
import { hasSubscription } from '../util/appUtil';
import type { Application } from '@dt/user-api/mobile_apps';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/types';

const findingsSelector = (state: State) => state.securityFindings;

type FindingsType = $ReadOnlyArray<SecurityFinding>;
const appsSelector = (state: State) => state.apps;

function mapSortStringToSortOrder(sortString: string): SortOrder {
  const order =
    sortString[0] === '-'
      ? SORT_DIRECTION.DESCENDING
      : SORT_DIRECTION.ASCENDING;
  const field = sortString.replace('+', '').replace('-', '');
  return [{ field, order }];
}

const sortString = (state: State) => state.strings['sortString'];

const findingsSorted = () =>
  createSelector(
    findingsSelector,
    sortString,

    (findings, sortString: mixed) =>
      getSortFn(
        typeof sortString === 'string'
          ? mapSortStringToSortOrder(sortString)
          : null,
      )(findings),
  );

const makeFindingsFilterCreator = (
  search?: null | {
    [string]: void | null | string | $ReadOnlyArray<string>,
    ...,
  },
  category?: string,
) => {
  const query: Query = {
    compliancePolicy: getComplianceSearchFilter(),
  };

  if (search && search.category && typeof search.category === 'string') {
    query.category = search.category;
  }

  if (category) {
    query.category = category;
  }

  return findingsFilterCreator(query);
};

type CategoryParam = {
  +match: { +params: { +category: void | string, ... }, ... },
  ...
};

export type SearchAndCategoryParam = SearchParam & CategoryParam;

export const findingsFromCategory = createSelector<
  State,
  SearchAndCategoryParam,
  FindingsType,
  _,
  _,
  _,
>(
  findingsSorted(),
  (state, props) => props.match && props.match.params.category,
  search,

  (findings, category, search) =>
    findings
      ? findings.filter(makeFindingsFilterCreator(search, category))
      : [],
);

export type FindingIdOrLocationParam = SearchParam & {
  +match: { +params: { +findingId: void | string, ... }, ... },
  ...
};

export const findingIdFromParam = createSelector<
  State,
  FindingIdOrLocationParam,
  ?string,
  _,
  _,
>(
  (state, props) => {
    const { match } = props;
    if (match && match.params.findingId) {
      return match.params.findingId;
    }
  },

  findingId => (Array.isArray(findingId) ? null : findingId),
);

export const findingFromParam = createSelector<
  State,
  FindingIdOrLocationParam,
  ?SecurityFinding,
  _,
  _,
>(
  findingsSorted(),
  findingIdFromParam,

  (findings, findingId) =>
    findings ? findings.find(finding => finding.id === findingId) : null,
);

export const paramsForCategory = createSelector<
  State,
  CategoryParam,
  { filter?: void | string, ... },
  _,
>(
  (state, props) => props.match && props.match.params.category,

  category => {
    const request = {};

    if (category === 'all' || category === 'compliance') {
      return request;
    }

    request['filter'] = SecurityFindingsFilterEnum.PRIORITY_ALERTS;
    return request;
  },
);

const simpleSearch = memoize((finding, search) => {
  if (!finding || !search) {
    return null;
  }

  const searchTarget = JSON.stringify(finding).toLowerCase();
  return searchTarget.indexOf(search.toLowerCase()) !== -1;
});

const getSearchTextFinding = createSelector<State, SearchParam, string, _, _>(
  search,
  searchParams => {
    return (
      (searchParams
        ? Array.isArray(searchParams.searchText)
          ? searchParams.searchText[0]
          : searchParams.searchText
        : '') || ''
    );
  },
);

export const findingsFromSearch = createSelector<
  State,
  SearchParam,
  FindingsType,
  _,
  _,
  _,
>(
  findingsSorted(),
  getSearchTextFinding,

  (findings, searchText) =>
    findings
      .filter(finding => {
        return simpleSearch(finding, searchText);
      })
      .filter(
        findingsFilterCreator({
          compliancePolicy: getComplianceSearchFilter(),
        }),
      ),
);

export const linkedAppsFromFindingParam = createSelector<
  State,
  FindingIdOrLocationParam,
  $ReadOnlyArray<Application> | null,
  _,
  _,
>(
  appsSelector,
  findingFromParam,

  (apps, finding) => {
    if (!apps || !finding) {
      return null;
    }

    const app = apps.find(app => app.id === finding.mobile_app_id);
    if (!app) {
      return null;
    }

    let storeOrEnterpriseApp;
    if (
      app.release_type === MobileAppReleaseTypeValues.APP_STORE ||
      app.release_type === MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      storeOrEnterpriseApp = app;
    } else {
      const app_store_customer_mobile_app_id = app.app_store_customer_mobile_app_id
        ? app.app_store_customer_mobile_app_id
        : null;

      storeOrEnterpriseApp =
        app_store_customer_mobile_app_id &&
        apps.find(a => a.id === app_store_customer_mobile_app_id);
    }

    if (!storeOrEnterpriseApp) {
      return null;
    }

    let linkedMobileApps = apps.filter(
      a =>
        storeOrEnterpriseApp &&
        a.id !== app.id &&
        a.app_store_customer_mobile_app_id === storeOrEnterpriseApp.id &&
        hasSubscription(a),
    );
    if (
      app.release_type !== MobileAppReleaseTypeValues.APP_STORE &&
      app.release_type !== MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      linkedMobileApps.push(storeOrEnterpriseApp);
    }

    return linkedMobileApps;
  },
);

export type SearchAndFindingIdOrLocationParam = SearchParam &
  FindingIdOrLocationParam;

export const linkedIssuesFromFindingParam = createSelector<
  State,
  SearchAndFindingIdOrLocationParam,
  $ReadOnlyArray<LinkedAppIssueType> | null,
  _,
  _,
  _,
>(
  appsSelector,
  findingsSorted(),
  findingFromParam,

  (apps, sortedFindings, finding) => {
    if (!apps || !sortedFindings || !finding) {
      return null;
    }

    const appFindings = groupBy(sortedFindings, f => f.mobile_app_id);
    const app = apps.find(app => app.id === finding.mobile_app_id);
    if (!app) {
      return null;
    }

    let storeOrEnterpriseApp;
    if (
      app.release_type === MobileAppReleaseTypeValues.APP_STORE ||
      app.release_type === MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      storeOrEnterpriseApp = app;
    } else {
      const app_store_customer_mobile_app_id = app.app_store_customer_mobile_app_id
        ? app.app_store_customer_mobile_app_id
        : null;

      storeOrEnterpriseApp =
        app_store_customer_mobile_app_id &&
        apps.find(a => a.id === app_store_customer_mobile_app_id);
    }

    if (!storeOrEnterpriseApp) {
      return null;
    }

    let linkedMobileApps = apps.filter(
      a =>
        storeOrEnterpriseApp &&
        a.id !== app.id &&
        a.app_store_customer_mobile_app_id === storeOrEnterpriseApp.id &&
        hasSubscription(a),
    );

    if (
      app.release_type !== MobileAppReleaseTypeValues.APP_STORE &&
      app.release_type !== MobileAppReleaseTypeValues.ENTERPRISE
    ) {
      linkedMobileApps.push(storeOrEnterpriseApp);
    }

    let linkedIssues = [];
    linkedMobileApps.map(linkedApp => {
      const linkedFinding =
        appFindings &&
        appFindings[linkedApp.id] &&
        appFindings[linkedApp.id].find(
          appFinding => appFinding.issue_type_id === finding.issue_type_id,
        );

      if (linkedFinding) {
        const linkedAppWithFinding: LinkedAppIssueType = {
          linkedApp: linkedApp,
          linkedFinding: linkedFinding,
        };

        linkedIssues.push(linkedAppWithFinding);
      }
    });

    return linkedIssues;
  },
);
