// @flow
import { type Saga } from 'redux-saga';
import { all, call } from 'redux-saga/effects';

import { getSharedLinks } from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateToEnd,
} from '../resource_fetch/sagas';
import { sharedLinksMounted } from './actions';
import resourceType from './resourceType';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      sharedLinksMounted.toString(),
      function*(): Saga<void> {
        yield call(getAllSharedLinks);
      },
    ),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getSharedLinks, { cursor });
    }),
  ]);
}

export function* getAllSharedLinks(): Saga<void> {
  yield call(paginateToEnd, getSharedLinks, resourceType, {});
}
