// @flow
import { type State } from '../store_state_type';
import { createSelector } from 'reselect';
import { values } from '@dt/functions';

import { decorate } from './util';
import { type PolicyRule, type PolicyRuleList } from '@dt/horizon-api';
import {
  type PolicyRuleDecoratedList,
  type PolicyRuleDecorated,
} from './types';
import { policy_rule_types } from '../policy_rule_types/selectors';

export const policy_rules = ({ policy_rules }: State) => policy_rules;

export const getPolicyRuleFromRuleType = createSelector<
  State,
  { ruleTypeId: string, policyId: string, ... },
  ?PolicyRule,
  _,
  _,
  _,
>(
  (state, props = {}) => props.ruleTypeId,
  (state, props = {}) => props.policyId,
  policy_rules,
  (ruleTypeId, policyId, policy_rule_state) => {
    const policy_id_indexes =
      policy_rule_state.for_policy_and_rule_type_id[policyId];

    if (!policy_id_indexes) return null;
    const policy_rule_id = policy_id_indexes[ruleTypeId];

    const policy_rule = policy_rule_state.id[policy_rule_id];

    // Ignore those that were deleted.
    if (policy_rule && policy_rule.deleted_by_user_id) return null;

    return policy_rule;
  },
);

export const getPolicyRulesFromRuleTypes = createSelector<
  State,
  { ruleTypeIds: Array<string>, policyId: string, ... },
  PolicyRuleList,
  _,
  _,
  _,
>(
  (state, props = {}) => props.ruleTypeIds,
  (state, props = {}) => props.policyId,
  policy_rules,
  (ruleTypeIds, policyId, policy_rule_state) => {
    const policy_id_indexes =
      policy_rule_state.for_policy_and_rule_type_id[policyId];

    if (!policy_id_indexes) return [];

    const policy_rule_ids = ruleTypeIds.map(
      ruleTypeId => policy_id_indexes[ruleTypeId],
    );
    const policy_rules = policy_rule_ids.map(id => {
      const policy_rule = policy_rule_state.id[id];
      // Ignore those that were deleted.
      if (policy_rule && policy_rule.deleted_by_user_id) return null;
      return policy_rule;
    });

    return policy_rules.filter(Boolean);
  },
);

export const getAllPolicyRulesDecorated = createSelector<
  State,
  { ... },
  PolicyRuleDecoratedList,
  _,
  _,
  _,
>(policy_rules, policy_rule_types, (policy_rules, policy_rule_types) =>
  values(policy_rules.id)
    .map(policy_rule => decorate(policy_rule, policy_rule_types))
    .filter(Boolean),
);

export const getPolicyRuleDecoratedFromId = createSelector<
  State,
  {| id: string |},
  ?PolicyRuleDecorated,
  _,
  _,
  _,
>(
  (state, props = {}) => props.id,
  policy_rules,
  policy_rule_types,
  (policy_rule_id, policy_rules, policy_rule_types) => {
    const policy_rule = values(policy_rules.id).find(
      policy_rule => policy_rule.id === policy_rule_id,
    );
    if (!policy_rule) return null;
    return decorate(policy_rule, policy_rule_types);
  },
);
