// @flow
import { createReducerCreator, updateInList } from 'rezz';
import type { ActionType } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { type CommentsList, type Comment } from '@dt/horizon-api';
import {
  commentsReceived,
  commentInsert,
  commentInsertError,
  commentInsertSuccess,
  commentDelete,
  commentDeleteError,
  commentDeleteSuccess,
} from './actions';

export type CommentsState = {|
  insertSuccess: boolean,
  insertError: ?string,
  deleteSuccess: $ReadOnlyArray<{
    id: string,
    value: boolean,
    ...
  }>,
  deleteErrors: $ReadOnlyArray<{
    id: string,
    value: ?string,
    ...
  }>,
  deleteLoadings: $ReadOnlyArray<{
    id: string,
    value: boolean,
    ...
  }>,
  list: CommentsList,
|};

export const initialState: CommentsState = {
  insertSuccess: false,
  insertError: null,
  deleteSuccess: [],
  deleteErrors: [],
  deleteLoadings: [],
  list: [],
};

const replaceWithId = <T: ?string | boolean>(
  state: $ReadOnlyArray<{
    id: string,
    value: T,
    ...
  }>,
  id,
  value: T,
): $ReadOnlyArray<{
  id: string,
  value: T,
  ...
}> => {
  return [
    ...state.filter(l => l.id !== id),
    {
      id,
      value,
    },
  ];
};

export default createReducerCreator<CommentsState, Comment, _>({
  initialState: initialState,
  equalityCheck: (a, b) => a.id === b.id,
})(
  handleActions(
    {
      [commentInsert.toString()]: state => ({
        ...state,
        insertSuccess: false,
        insertError: null,
      }),
      [commentInsertSuccess.toString()]: state => ({
        ...state,
        insertSuccess: true,
        insertError: null,
      }),
      [commentInsertError.toString()]: (
        state,
        action: { payload: string, ... },
      ) => ({
        ...state,
        insertSuccess: false,
        insertError: action.payload,
      }),
      [commentDelete.toString()]: (
        state,
        action: ActionType<typeof commentDelete>,
      ) => ({
        ...state,
        deleteSuccess: replaceWithId(
          state.deleteSuccess,
          action.payload.commentId,
          false,
        ),
        deleteLoadings: replaceWithId(
          state.deleteLoadings,
          action.payload.commentId,
          true,
        ),
        deleteErrors: replaceWithId(
          state.deleteErrors,
          action.payload.commentId,
          null,
        ),
      }),
      [commentDeleteSuccess.toString()]: (
        state,
        action: ActionType<typeof commentDeleteSuccess>,
      ) => ({
        ...state,
        deleteSuccess: replaceWithId(
          state.deleteSuccess,
          action.payload.commentId,
          true,
        ),
        deleteLoadings: replaceWithId(
          state.deleteLoadings,
          action.payload.commentId,
          false,
        ),
        deleteErrors: replaceWithId(
          state.deleteErrors,
          action.payload.commentId,
          null,
        ),
      }),
      [commentDeleteError.toString()]: (
        state,
        action: ActionType<typeof commentDeleteError>,
      ) => ({
        ...state,
        deleteSuccess: replaceWithId(
          state.deleteSuccess,
          action.payload.commentId,
          false,
        ),
        deleteLoadings: replaceWithId(
          state.deleteLoadings,
          action.payload.commentId,
          false,
        ),
        deleteErrors: replaceWithId(
          state.deleteErrors,
          action.payload.commentId,
          action.payload.error,
        ),
      }),
    },
    initialState,
  ),
  (
    state = initialState,
    action: ActionType<typeof commentsReceived>,
    rezzOptions,
  ) => {
    return action.type === commentsReceived.toString()
      ? {
          ...state,
          list: updateInList(commentsReceived.toString())(
            state.list,
            action,
            // reez typings proxies reducers types,
            rezzOptions,
          ),
        }
      : state;
  },
);
