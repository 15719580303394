// @flow
import { type Saga } from 'redux-saga';
import { type ActionType } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { getAssetGroupMemberships } from './resource_fetch.sagas';
import { takePatternAndFetch } from '../../resource_fetch/sagas';
import {
  assetGroupsMembershipMounted,
  assetGroupMembershipCreate,
  assetGroupsMembershipReceived,
} from './actions';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { asset_groups } from '@dt/horizon-api';
import { chunk } from 'lodash/fp';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      assetGroupsMembershipMounted.toString(),
      function*(
        action: ActionType<typeof assetGroupsMembershipMounted>,
      ): Saga<void> {
        yield all([
          call(getAssetGroupMemberships, action.payload.assetGroupId),
        ]);
      },
    ),
    takeEvery(
      assetGroupMembershipCreate.toString(),
      assetGroupMembershipCreateSaga,
    ),
  ]);
}

export function* assetGroupMembershipCreateSaga(
  action: ActionType<typeof assetGroupMembershipCreate>,
): Saga<void> {
  const fullList = [
    ...action.payload.assets.cloud_resource_ids.map(id => ({
      type: 'cloud_resource_ids',
      id,
    })),
    ...action.payload.assets.restful_api_ids.map(id => ({
      type: 'restful_api_ids',
      id,
    })),
    ...action.payload.assets.web_application_ids.map(id => ({
      type: 'web_application_ids',
      id,
    })),
    ...action.payload.assets.network_service_ids.map(id => ({
      type: 'network_service_ids',
      id,
    })),
  ];

  const chunked = chunk(50)(fullList);

  for (let group of chunked) {
    const body = {
      cloud_resource_ids: [],
      restful_api_ids: [],
      web_application_ids: [],
      network_service_ids: [],
    };

    group.forEach(item => {
      body[item.type].push(item.id);
    });

    const response = yield* callPromise(
      asset_groups.memberships.create,
      action.payload.assetGroupId,
      body,
    );

    if (
      response._type === 'response' &&
      response.body.asset_group_memberships
    ) {
      yield put(
        assetGroupsMembershipReceived(response.body.asset_group_memberships),
      );
    }
  }
}
