//@flow
import React, { useRef, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { PolicyViolationsCommentsItem } from './PolicyViolationsCommentsItem';
import { PolicyViolationsCommentsListEmpty } from './PolicyViolationsCommentsListEmpty';
import isAfter from 'date-fns/is_after';

type Comment = {
  +id: ?string,
  +author_email: ?string,
  +date: ?string,
  +is_internal_comment: boolean,
  +text: ?string,
  ...
};

const sortComments = function sortComments(a: Comment, b: Comment) {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  if (isAfter(a.date, b.date)) {
    return 1;
  } else {
    return -1;
  }
};

type LoadingProps = {| +isLoading: true |};
type ImplementationProps = {|
  +isLoading?: false,
  +comments: ?$ReadOnlyArray<Comment>,
|};
type Props = LoadingProps | ImplementationProps;

/*
 * Lists out policy violation comments for the user.
 *
 * @param comments - Comment data to render.
 */
function PolicyViolationsCommentsListImplementation({
  comments,
}: ImplementationProps) {
  // Tracks whether the comments were scrolled on mount.
  // Used to start the user viewing the latest comments.
  const [hasStartedScrolled, setHasStartedScrolled] = useState<boolean>(false);

  // Tracks the container scroll context and bottom of container.
  // Used to automatically scroll the comments when new ones become available.
  const containerRef = useRef<null | HTMLDivElement>(null);
  const lastCommentItemRef = useRef<null | HTMLElement>(null);

  // Make sure comments are sorted.
  const sortedComments = comments ? comments.slice().sort(sortComments) : [];

  // Scroll *immediately* to the bottom of the comments when comments are upddated
  // and the user *was* at the bottom.
  // If the user wasn't at the bottom they want to be viewing latest comments.
  useEffect(() => {
    // Do we have refs yet?
    if (!lastCommentItemRef.current) return;
    if (!containerRef.current) return;

    // scrollIntoView is not mocked in JSDOM.
    if (!lastCommentItemRef.current.scrollIntoView) return;

    // Enable smooth scrolling, after we've scrolled to the bottom the first time.
    if (!hasStartedScrolled) {
      lastCommentItemRef.current.scrollIntoView(false);
      setHasStartedScrolled(true);
    } else {
      // Don't be auto scrolling unless the user expects new comments?
      // Only scroll to the bottom if the user is viewing the last comment.
      if (
        containerRef.current.scrollTop + containerRef.current.offsetHeight >
        containerRef.current.scrollHeight -
          (lastCommentItemRef.current.offsetHeight + 40)
      ) {
        lastCommentItemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [comments, hasStartedScrolled]);

  return (
    <>
      {/*
          This container div is here and expanded to force a scroll area to this level
          and not to the parent element.

          DO NOT update the `overflow` to something other than `auto`.
          Scroll area behavior is implemented differently per browser.
      */}
      <div
        style={{ width: '100%', height: '100%', overflow: 'auto' }}
        ref={containerRef}
      >
        {sortedComments.length <= 0 ? (
          <PolicyViolationsCommentsListEmpty variant="center" gutterBottom />
        ) : (
          <>
            {sortedComments.map(comment => (
              <PolicyViolationsCommentsItem
                ref={element => {
                  // $FlowFixMe - This is mixed here. Force it to HTMLElement type.
                  lastCommentItemRef.current = element;
                }}
                key={comment.id}
                author={comment.author_email}
                date={comment.date}
                is_internal_comment={comment.is_internal_comment}
              >
                {comment.text}
              </PolicyViolationsCommentsItem>
            ))}
          </>
        )}
      </div>
    </>
  );
}

function PolicyViolationsCommentsListSkeleton() {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={80} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={80} animation="pulse" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width={'100%'} height={80} animation="pulse" />
      </Grid>
    </Grid>
  );
}

const PolicyViolationsCommentsListComponent = function PolicyViolationsCommentsList(
  props: Props,
) {
  if (props.isLoading) {
    return <PolicyViolationsCommentsListSkeleton {...props} />;
  }

  return <PolicyViolationsCommentsListImplementation {...props} />;
};

export const PolicyViolationsCommentsList = PolicyViolationsCommentsListComponent;
