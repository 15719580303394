//@flow

const platform = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WINDOWS_PHONE: 'WINDOWS_PHONE',
  ANDROID_AMAZON: 'ANDROID_AMAZON',
  ANDROID_OCULUS: 'ANDROID_OCULUS',
  ANDROID_FACEBOOK_PORTAL: 'ANDROID_FACEBOOK_PORTAL',
  MACOS: 'MACOS',
  IOS_ON_MAC: 'IOS_ON_MAC',
};

export default platform;

export type MobileAppPlatformEnum = $Keys<typeof platform>;
