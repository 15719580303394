//@flow
import React, { memo, type ElementConfig } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import AndroidLogo from '@material-ui/icons/Android';
import GenericSmartphoneIcon from '@material-ui/icons/Smartphone';
import AppleLogo from '@dt/material-components/svg_components/AppleLogo';
import WindowsLogo from '@dt/material-components/svg_components/WindowsLogo';
import OculusLogo from '@dt/material-components/svg_components/OculusLogo';
import AmazonLogo from '@dt/material-components/svg_components/AmazonLogo';

const PLATFORM_TO_COMPONENT = {
  IOS: AppleLogo,
  IOS_ON_MAC: AppleLogo,
  ANDROID: AndroidLogo,
  WINDOWS_PHONE: WindowsLogo,
  ANDROID_OCULUS: OculusLogo,
  ANDROID_AMAZON: AmazonLogo,
  ANDROID_FACEBOOK_PORTAL: AndroidLogo,
  CROSS_PLATFORM: GenericSmartphoneIcon,
  SERVER_SIDE: GenericSmartphoneIcon,
  MACOS: GenericSmartphoneIcon,
  UNKNOWN: GenericSmartphoneIcon,
};

export type PlatformIconTypes = $Keys<typeof PLATFORM_TO_COMPONENT>;

type Props = { platform: PlatformIconTypes, ...ElementConfig<typeof SvgIcon> };

export function isSupportedPlatform(platform: string): boolean %checks {
  return (
    platform === 'ANDROID' ||
    platform === 'IOS' ||
    platform === 'MACOS' ||
    platform === 'WINDOWS_PHONE' ||
    platform === 'ANDROID_OCULUS' ||
    platform === 'ANDROID_AMAZON' ||
    platform === 'ANDROID_FACEBOOK_PORTAL' ||
    platform === 'IOS_ON_MAC'
  );
}

const MobileAppsPlatformIconComponent = function MobileAppsPlatformIcon({
  platform,
  ...rest
}: Props) {
  let Comp = PLATFORM_TO_COMPONENT[platform];
  return <Comp {...rest} />;
};

export const MobileAppsPlatformIcon = memo<Props>(
  MobileAppsPlatformIconComponent,
);
