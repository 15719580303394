var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Menu, MenuItem } from '@material-ui/core';
import React from 'react';
// @ts-ignore
import WebSecureIcon from '@dt/brand/product-icon-web-secure';
// @ts-ignore
import APISecureIcon from '@dt/brand/product-icon-api-secure';
// @ts-ignore
import CloudSecureIcon from '@dt/brand/product-icon-cloud-secure';
// @ts-ignore
import MobileSecureIcon from '@dt/brand/product-icon-mobile-secure';
// @ts-ignore
import DTIcon from '@dt/brand/dt-logo-icon-only';
import useScsMenuItem from '../hooks/use_scs_menu_item';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { ExtLink } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
// @ts-ignore
import { useSession } from '@dt/ahura/src/session';
var dtProducts = [
    {
        to: '/dashboard',
        name: 'Dashboard',
        icon: DTIcon,
    },
    {
        to: '/api/v2',
        name: 'API Secure',
        icon: APISecureIcon,
    },
    {
        to: '/cloud',
        name: 'Cloud Secure',
        icon: CloudSecureIcon,
    },
    {
        to: '/mobile-secure/v2/inventory/apps',
        name: 'Mobile Secure',
        icon: MobileSecureIcon,
    },
    {
        to: '/web',
        name: 'Web Secure',
        icon: WebSecureIcon,
    },
];
var AppsMenu = function (_a) {
    var anchorEl = _a.anchorEl, handleClose = _a.handleClose;
    var loading = useSession({ unauthenticatedRedirect: false }).loading;
    var scsMenuItem = useScsMenuItem({ loading: loading });
    if (loading)
        return null;
    return (React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        } },
        React.createElement("div", null, __spreadArray(__spreadArray([], dtProducts, true), [scsMenuItem], false).map(function (product, idx) {
            var DTProductIcon = product === null || product === void 0 ? void 0 : product.icon;
            return (React.createElement(ExtLink, { key: idx, to: product === null || product === void 0 ? void 0 : product.to, target: "_self", onClick: handleClose },
                React.createElement(MenuItem, null,
                    React.createElement("div", { style: {
                            maxWidth: 40,
                            maxHeight: 40,
                            minWidth: 40,
                            minHeight: 40,
                            width: 40,
                            height: 40,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '16px',
                        } },
                        React.createElement(DTProductIcon, { foregroundColor: palette.white, backgroundColor: palette.brand30 })), product === null || product === void 0 ? void 0 :
                    product.name)));
        }))));
};
export default AppsMenu;
