//@flow
import { type Saga } from 'redux-saga';
import { put, call, delay } from 'redux-saga/effects';
import { callSaga } from '@dt/redux-saga-wrapped-effects';
import { setRowCount } from '../../reducers/rowCount';
import { stringFromParametricRequest } from '@dt/string';
import { lastPageReceived } from '../../actions';
import flatten from 'lodash/fp/flatten';
import type { PaginatedResponse } from '@dt/user-api/_common';

type Params = { +[key: string]: ?string, ... };
let paramCache = [];

function findIndexInCache(type, params = {}) {
  const paramsKeys = Object.keys(params);
  return paramCache.findIndex(paramsInfo => {
    if (paramsInfo.type !== type) {
      return false;
    }

    const cacheParamsKeys = Object.keys(paramsInfo.params || {});
    if (paramsKeys.length !== cacheParamsKeys.length) {
      return false;
    }

    return paramsKeys.every(key => params[key] === paramsInfo.params[key]);
  });
}

function getCachedProp(name, type: *, params?: Params): ?string {
  const index = findIndexInCache(type, params);
  if (paramCache[index] && typeof paramCache[index][name] !== 'undefined') {
    return paramCache[index][name];
  }
}

function getCursor(type: *, params?: Params): ?string {
  return getCachedProp('cursor', type, params);
}

function getFinished(type: *, params?: Params): ?string {
  return getCachedProp('finished', type, params);
}

function setCursor(
  type: *,
  params?: Params,
  cursor: ?string,
  finished: ?boolean = false,
): ?string {
  const index = findIndexInCache(type, params);
  const prev = paramCache[index];
  if (prev) {
    paramCache = paramCache.filter(item => item !== prev);
  }

  paramCache = paramCache.concat({
    ...prev,
    params,
    cursor,
    type,
    finished,
  });

  return cursor;
}

export function clearCache() {
  paramCache = [];
}

export default function* paginate<R: { ... }, T: PaginatedResponse<R>>(
  type: string,
  params?: Params,
  inner: (params?: Params) => Saga<T>,
): Saga<T> {
  const cursor: string = getCursor(type, params) || '';

  // We want errors to propogate
  /* eslint-disable redux-saga/no-unhandled-errors */
  const response: T = yield call(
    inner,
    cursor ? { ...params, cursor } : params,
  );

  if (response && response.pagination_information) {
    if (typeof response.pagination_information.next_cursor === 'string') {
      setCursor(type, params, response.pagination_information.next_cursor);
    } else {
      setCursor(type, params, null, true);
      yield put(lastPageReceived(type, params));
      yield put(setRowCount(stringFromParametricRequest(type, params), -1));
    }
    if (
      response.pagination_information &&
      ['string', 'number'].includes(
        typeof response.pagination_information.total_count,
      )
    ) {
      const { total_count } = response.pagination_information;
      yield put(
        setRowCount(
          stringFromParametricRequest(type, params),
          parseInt(total_count, 10),
        ),
      );
    }
  }

  /* eslint-enable redux-saga/no-unhandled-errors */
  return response;
}

export function* paginateToEnd<
  A,
  Args: $ReadOnlyArray<A>,
  T,
  R: $ReadOnlyArray<T> | void,
>(
  inner: (...args: Args) => Saga<R>,
  type: string,
  params?: Params,
  ...args: Args
): Saga<$ReadOnlyArray<T> | void> {
  if (getFinished(type, params)) {
    return;
  }

  let collectedResults: $ReadOnlyArray<$ReadOnlyArray<T>> = [];

  do {
    // We want errors to propogate
    const result = yield* callSaga(inner, ...args); //eslint-disable-line redux-saga/no-unhandled-errors
    if (result) {
      collectedResults = [...collectedResults, result];
    }

    // Stagger this loop so that tests can page through by using setImmediate.
    // Without this, all the pages come in at once on the next tick, so it's
    // impossible to test an intermediate state. This should have little to no
    // impact on real use.
    yield delay(0);
  } while (getCursor(type, params));

  return collectedResults.length
    ? flatten(collectedResults.filter(Boolean))
    : undefined;
}
