// @flow
import { type Saga } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { select } from '@dt/redux-saga-wrapped-effects';

import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateWhile,
} from '../resource_fetch/sagas';
import { getEventsList } from '../events/resource_fetch.sagas';
import resourceType from './resourceType';
import { getDiscoveryEvents } from './selectors';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      'mounted/events/dashboard',
      function*(): Saga<void> {
        yield call(getEventsList, {});
      },
    ),
    call(
      takePatternAndFetch,
      'mounted/events/discovery',
      function*(): Saga<void> {
        yield call(getDiscoveryEventsList);
      },
    ),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getEventsList, { cursor });
    }),
  ]);
}

const getDiscoveryEventsList = function*(): Saga<void> {
  yield call(
    paginateWhile,
    getEventsList,
    resourceType,
    {},
    function*(): Saga<boolean> {
      const events = yield* select(getDiscoveryEvents, {});
      return !events.length;
    },
  );
};
