// @flow
import { createAction } from 'redux-actions';
import { type CloudResourceType } from '@dt/horizon-api';

export const inventorySearchMounted = createAction<
  'inventory/search/mounted',
  [],
  { key: 'inventory_search', ... },
>('inventory/search/mounted', () => ({
  key: 'inventory_search',
}));

export const inventorySearchFetch = createAction<
  'inventory/search/fetch',
  [string],
  { search_id: string, key: 'inventory_search', ... },
>('inventory/search/fetch', search_id => ({
  search_id,
  key: 'inventory_search',
}));

export const inventoryCreateSearchFailed = createAction<
  'inventory/search/failed',
  [],
  { ... },
>('inventory/search/failed', () => ({}));

export const inventoryCreateSearchSucceed = createAction<
  'inventory/search/succeed',
  [string],
  { asset_search_id: string, ... },
>('inventory/search/succeed', asset_search_id => ({
  asset_search_id,
}));

export const inventorySearchAssetTypesSelectMounted = createAction<
  'inventory/search/mounted/asset/types',
  [],
  { key: 'inventory_search_asset_types', ... },
>('inventory/search/mounted/asset/types', () => ({
  key: 'inventory_search_asset_types',
}));

export const inventorySearchToggleAnAssetType = createAction<
  'inventory/search/query/asset_type',
  [string],
  string,
>('inventory/search/query/asset_type', asset_type => asset_type);

export const inventorySearchToggleCloudResourceCategory = createAction<
  'inventory/search/query/cloud_resource_category',
  [string],
  string,
>(
  'inventory/search/query/cloud_resource_category',
  cloud_resource_category => cloud_resource_category,
);

export const inventorySearchToggleCloudResourceType = createAction<
  'inventory/search/query/cloud_resource_type',
  [string],
  string,
>(
  'inventory/search/query/cloud_resource_type',
  cloud_resource_type => cloud_resource_type,
);

export const inventorySearchCloudResourceTypesRecieved = createAction<
  'inventory/search/query/cloud_resource_type/list',
  [Array<string>],
  Array<string>,
>(
  'inventory/search/query/cloud_resource_type/list',
  cloud_resource_type => cloud_resource_type,
);

export const inventorySearchToggleCloudProvider = createAction<
  'inventory/search/query/hosted_on_cloud_provider',
  [string],
  string,
>(
  'inventory/search/query/hosted_on_cloud_provider',
  hosted_on_cloud_provider => hosted_on_cloud_provider,
);

export const inventorySearchCloudProvidersReceived = createAction<
  'inventory/search/query/hosted_on_cloud_provider/list',
  [Array<string>],
  Array<string>,
>(
  'inventory/search/query/hosted_on_cloud_provider/list',
  hosted_on_cloud_providers => hosted_on_cloud_providers,
);

export const inventorySearchModifyText = createAction<
  'inventory/search/query/text',
  [string],
  { text: string, ... },
>('inventory/search/query/text', text => ({ text }));

export const inventorySearchClicked = createAction<
  'inventory/search/clicked',
  [],
  { ... },
>('inventory/search/clicked', () => ({}));

export const InventorySearchcloudResourceTypesReceived = createAction<
  'inventory/search/cloud_resource_type/fetched',
  [$ReadOnlyArray<CloudResourceType>],
  { cloudResourceTypes: $ReadOnlyArray<CloudResourceType>, ... },
>('inventory/search/cloud_resource_type/fetched', cloudResourceTypes => ({
  cloudResourceTypes,
}));
