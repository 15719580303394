// @flow
import { createAction } from 'redux-actions';
import { type SharedLinksList } from '@dt/horizon-api';

export const sharedLinksReceived = createAction<
  'shared_links/received',
  [SharedLinksList],
  SharedLinksList,
>('shared_links/received', shared_links => shared_links);

export const sharedLinksMounted = createAction<
  'shared_links/mounted',
  [],
  { key: 'shared_links', ... },
>('shared_links/mounted', () => ({ key: 'shared_links' }));

export const sharedLinksDeleteSuccess = createAction<
  'shared_links/delete/success',
  [string],
  string,
>('shared_links/delete/success', id => id);
