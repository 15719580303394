//@flow
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import PolicyViolationIcon from './PolicyViolationIcon';
import {
  PolicyRuleTypeRelevance,
  type PolicyRuleTypeRelevanceType,
} from '@dt/horizon-api';
import { palette, type OldPaletteToNewPaletteType } from '@dt/theme';

const PolicyViolationStatusToPaletteColorMap = {
  [PolicyRuleTypeRelevance.URGENT]: palette.red,
  [PolicyRuleTypeRelevance.IMPORTANT]: palette.yellow,
  [PolicyRuleTypeRelevance.PROACTIVE]: palette.blue,
  RESOLVED: palette.green,
};

const RelevanceToType: {
  +[PolicyRuleTypeRelevanceType]: OldPaletteToNewPaletteType,
  ...,
} = {
  [PolicyRuleTypeRelevance.URGENT]: 'bad',
  [PolicyRuleTypeRelevance.IMPORTANT]: 'warning',
  [PolicyRuleTypeRelevance.PROACTIVE]: 'info',
};

type Props = {|
  relevance: ?$Keys<typeof RelevanceToType>,
  huge?: boolean,
  tiny?: boolean,
  white?: boolean,
|};

export default React.memo<Props>(function RelevanceAvatar({
  relevance,
  ...props
}: Props) {
  return (
    <Avatar
      style={{
        backgroundColor: relevance
          ? PolicyViolationStatusToPaletteColorMap[relevance]
          : PolicyViolationStatusToPaletteColorMap['RESOLVED'],
        display: 'inline-flex',
        width: props.tiny ? 24 : 32,
        height: props.tiny ? 24 : 32,
      }}
    >
      <PolicyViolationIcon
        color={palette.white}
        type={relevance ? RelevanceToType[relevance] : 'good'}
        {...props}
      />
    </Avatar>
  );
});
