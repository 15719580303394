// @flow
import type { FindingPriorityEnum } from '@dt/enums/FindingPriorityEnum';
import type { SecurityTemplateSeverityEnum } from '@dt/enums/SecurityTemplateSeverityEnum';
import type { CompliancePolicyEnum } from '@dt/enums/CompliancePolicyEnum';
import type { ReleaseType } from '@dt/enums/MobileAppReleaseTypeEnum';
import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import type { FilterStore } from '../reducers/filters';
import type { FindingSortChoiceEnum } from '@dt/user-api/search_queries';
import type { SecurityTemplateImportanceTagEnum } from '@dt/enums/SecurityTemplateImportanceTagEnum';

export const ActionEnum: {
  SET_KEYWORDS_FILTER: 'filter/set-keywords',
  SET_MOBILE_APP_IDS_FILTER: 'filter/set-mobile-app-ids',
  SET_STORE_BLOCKERS_FILTER: 'filter/set-store-blockers',
  SET_PRIORITY_FILTER: 'filter/set-priority',
  SET_SEVERITY_FILTER: 'filter/set-severity',
  SET_COMPLIANCE_POLICY_FILTER: 'filter/set-compliance-policy',
  SET_STATUS_FILTER: 'filter/set-status',
  SET_DATE_FILTER: 'filter/set-date',
  SET_DATE_FROM_FILTER: 'filter/set-date-from',
  SET_DATE_TO_FILTER: 'filter/set-date-to',
  SET_CURRENT_STATUS_FILTER: 'filter/set-current-status',
  SET_RELEASE_TYPE_FILTER: 'filter/set-release-type',
  SET_MIN_AGE_FILTER: 'filter/set-min-age',
  SET_FINDINGS_SORT: 'filter/set-findings-sort',
  TOGGLE_SHOWING: 'filter/toggle-showing',
  ADD_FILTER: 'filter/add',
  ADDED_FILTER: 'filters/add-success',
  REMOVE_FILTER: 'filter/remove',
  REMOVED_FILTER: 'filter/remove-success',
  SET_AS_CURRENT_FILTER: 'filter/set-as-current',
  RESET_FILTER: 'filter/reset',
  REQUEST_FILTERS_FROM_SERVER: 'filter/request-from-server',
  FILTERS_LOADED_FROM_SERVER: 'filter/loaded-from-server',
  OPEN_BUTTON_CLICKED: 'filter/search-open-clicked',
  SET_GLOBAL_FILTER: 'filter/set-global-filter',
  SAVE_REPORT_CLICKED: 'filter/save-report/clicked',
  ...
} = {
  SET_MOBILE_APP_IDS_FILTER: 'filter/set-mobile-app-ids',
  SET_KEYWORDS_FILTER: 'filter/set-keywords',
  SET_STORE_BLOCKERS_FILTER: 'filter/set-store-blockers',
  SET_PRIORITY_FILTER: 'filter/set-priority',
  SET_SEVERITY_FILTER: 'filter/set-severity',
  SET_COMPLIANCE_POLICY_FILTER: 'filter/set-compliance-policy',
  SET_STATUS_FILTER: 'filter/set-status',
  SET_DATE_FILTER: 'filter/set-date',
  SET_DATE_FROM_FILTER: 'filter/set-date-from',
  SET_DATE_TO_FILTER: 'filter/set-date-to',
  SET_CURRENT_STATUS_FILTER: 'filter/set-current-status',
  SET_RELEASE_TYPE_FILTER: 'filter/set-release-type',
  SET_MIN_AGE_FILTER: 'filter/set-min-age',
  SET_FINDINGS_SORT: 'filter/set-findings-sort',
  TOGGLE_SHOWING: 'filter/toggle-showing',
  ADD_FILTER: 'filter/add',
  ADDED_FILTER: 'filters/add-success',
  REMOVE_FILTER: 'filter/remove',
  REMOVED_FILTER: 'filter/remove-success',
  SET_AS_CURRENT_FILTER: 'filter/set-as-current',
  RESET_FILTER: 'filter/reset',
  REQUEST_FILTERS_FROM_SERVER: 'filter/request-from-server',
  FILTERS_LOADED_FROM_SERVER: 'filter/loaded-from-server',
  OPEN_BUTTON_CLICKED: 'filter/search-open-clicked',
  SET_GLOBAL_FILTER: 'filter/set-global-filter',
  SAVE_REPORT_CLICKED: 'filter/save-report/clicked',
};

/** Used to type "options" for SelectField or MultiSelectField */
export type Options<V> = $ReadOnlyArray<{| +label: string, +value: V |}>;

// Types
export type MinAge = '' | number;

export type DateRange = {
  type: string,
  from?: Date,
  to?: Date,
  ...
};

export type DateType =
  | 'ANY_TIME'
  | 'LAST_7_DAYS'
  | 'LAST_30_DAYS'
  | 'LAST_90_DAYS'
  | 'LAST_QUARTER'
  | 'LAST_YEAR'
  | 'CUSTOM';

export type SavedFilters = { [id: string]: Filter, ... };

export type FilterValue = $ElementType<FilterStore, 'findingsCriteria'>;

export type Filter = {
  name: string,
  value: FilterValue,
};

export type FilterActions =
  | SetKeywordsAction
  | SetPriorityAction
  | SetStoreBlockersAction
  | SetSeverityAction
  | SetCompliancePolicyAction
  | SetStatusAction
  | SetDateAction
  | SetDateFromAction
  | SetDateToAction
  | SetCurrentStatusAction
  | SetReleaseTypeAction
  | SetMinAgeAction
  | SetFindingsSortAction
  | AddFilterAction
  | RemoveFilterAction
  | SetAsCurrentAction
  | ResetAction
  | RequestFiltersFromServerAction
  | FiltersLoadedFromServerAction
  | AddedFilterAction
  | RemovedFilterAction
  | SetGlobalFilterAction;

// Action Types
export type SetKeywordsAction = {
  type: typeof ActionEnum.SET_KEYWORDS_FILTER,
  payload: ?string,
  ...
};

export type SetPriorityAction = {
  type: typeof ActionEnum.SET_PRIORITY_FILTER,
  payload: $ReadOnlyArray<FindingPriorityEnum | 'ANY'>,
  ...
};
export type SetMobileAppIdsAction = {
  type: typeof ActionEnum.SET_MOBILE_APP_IDS_FILTER,
  payload: string[],
  ...
};
export type SetStoreBlockersAction = {
  type: typeof ActionEnum.SET_STORE_BLOCKERS_FILTER,
  payload: $ReadOnlyArray<SecurityTemplateImportanceTagEnum | 'ANY'>,
  ...
};
export type SetSeverityAction = {
  type: typeof ActionEnum.SET_SEVERITY_FILTER,
  payload: $ReadOnlyArray<SecurityTemplateSeverityEnum | 'ANY'>,
  ...
};
export type SetCompliancePolicyAction = {
  type: typeof ActionEnum.SET_COMPLIANCE_POLICY_FILTER,
  payload: $ReadOnlyArray<CompliancePolicyEnum | 'ANY'>,
  ...
};
export type SetStatusAction = {
  type: typeof ActionEnum.SET_STATUS_FILTER,
  payload: $ReadOnlyArray<FindingTargetStatusEnum | 'ANY'>,
  ...
};
export type SetDateAction = {
  type: typeof ActionEnum.SET_DATE_FILTER,
  payload: DateType,
  ...
};
export type SetDateFromAction = {
  type: typeof ActionEnum.SET_DATE_FROM_FILTER,
  payload: Date,
  ...
};
export type SetDateToAction = {
  type: typeof ActionEnum.SET_DATE_TO_FILTER,
  payload: Date,
  ...
};
export type SetCurrentStatusAction = {
  type: typeof ActionEnum.SET_CURRENT_STATUS_FILTER,
  payload: $ReadOnlyArray<FindingTargetStatusEnum | 'ANY'>,
  ...
};
export type SetReleaseTypeAction = {
  type: typeof ActionEnum.SET_RELEASE_TYPE_FILTER,
  payload: $ReadOnlyArray<ReleaseType | 'ANY'>,
  ...
};
export type SetMinAgeAction = {
  type: typeof ActionEnum.SET_MIN_AGE_FILTER,
  payload: MinAge,
  ...
};
export type SetFindingsSortAction = {
  type: typeof ActionEnum.SET_FINDINGS_SORT,
  payload: FindingSortChoiceEnum,
  ...
};

export type SetKeywordsFilterFn = typeof setKeywordsFilter;
export const setKeywordsFilter = (p: string): SetKeywordsAction => ({
  type: ActionEnum.SET_KEYWORDS_FILTER,
  payload: p,
});

export type SetMobileAppIdsFilter = typeof setMobileAppIdsFilter;
export const setMobileAppIdsFilter = (p: string[]): SetMobileAppIdsAction => ({
  type: ActionEnum.SET_MOBILE_APP_IDS_FILTER,
  payload: p,
});

export type SetPriorityFilterFn = typeof setPriorityFilter;
export const setPriorityFilter = (
  p: $ReadOnlyArray<FindingPriorityEnum | 'ANY'>,
): SetPriorityAction => ({
  type: ActionEnum.SET_PRIORITY_FILTER,
  payload: p,
});

export type SetStoreBlockersFilterFn = typeof setStoreBlockersFilter;
export const setStoreBlockersFilter = (
  p: $ReadOnlyArray<SecurityTemplateImportanceTagEnum | 'ANY'>,
): SetStoreBlockersAction => ({
  type: ActionEnum.SET_STORE_BLOCKERS_FILTER,
  payload: p,
});

export type SetSeverityFilterFn = typeof setSeverityFilter;
export const setSeverityFilter = (
  s: $ReadOnlyArray<SecurityTemplateSeverityEnum | 'ANY'>,
): SetSeverityAction => ({
  type: ActionEnum.SET_SEVERITY_FILTER,
  payload: s,
});

export type SetCompliancePolicyFilterFn = typeof setCompliancePolicyFilter;
export const setCompliancePolicyFilter = (
  compliancePolicy: $ReadOnlyArray<CompliancePolicyEnum | 'ANY'>,
): SetCompliancePolicyAction => ({
  type: ActionEnum.SET_COMPLIANCE_POLICY_FILTER,
  payload: compliancePolicy,
});

export type SetStatusFilterFn = typeof setStatusFilter;
export const setStatusFilter = (
  s: $ReadOnlyArray<FindingTargetStatusEnum | 'ANY'>,
): SetStatusAction => ({
  type: ActionEnum.SET_STATUS_FILTER,
  payload: s,
});

export type SetDateFilterFn = typeof setDateFilter;
export const setDateFilter = (d: DateType): SetDateAction => ({
  type: ActionEnum.SET_DATE_FILTER,
  payload: d,
});

export type SetDateFromFilterFn = typeof setDateFromFilter;
export const setDateFromFilter = (d: Date): SetDateFromAction => ({
  type: ActionEnum.SET_DATE_FROM_FILTER,
  payload: d,
});

export type SetDateToFilterFn = typeof setDateToFilter;
export const setDateToFilter = (d: Date): SetDateToAction => ({
  type: ActionEnum.SET_DATE_TO_FILTER,
  payload: d,
});

export type SetCurrentStatusFilterFn = typeof setCurrentStatusFilter;
export const setCurrentStatusFilter = (
  s: $ReadOnlyArray<FindingTargetStatusEnum | 'ANY'>,
): SetCurrentStatusAction => ({
  type: ActionEnum.SET_CURRENT_STATUS_FILTER,
  payload: s,
});

export type SetReleaseTypeFilterFn = typeof setReleaseType;
export const setReleaseType = (
  r: $ReadOnlyArray<ReleaseType | 'ANY'>,
): SetReleaseTypeAction => ({
  type: ActionEnum.SET_RELEASE_TYPE_FILTER,
  payload: r,
});

export type SetMinAgeFn = typeof setMinAge;
export const setMinAge = (n: MinAge): SetMinAgeAction => ({
  type: ActionEnum.SET_MIN_AGE_FILTER,
  payload: n,
});

export type SetFindingsSortFn = typeof setFindingsSort;
export const setFindingsSort = (
  s: FindingSortChoiceEnum,
): SetFindingsSortAction => ({
  type: ActionEnum.SET_FINDINGS_SORT,
  payload: s,
});

export type AddFilterAction = {
  type: typeof ActionEnum.ADD_FILTER,
  payload: {
    name: string,
    value: FilterValue,
    ...
  },
  ...
};
export type AddFilterFn = typeof addFilter;
export const addFilter = (name: string, f: FilterValue): AddFilterAction => ({
  type: ActionEnum.ADD_FILTER,
  payload: {
    name: name,
    value: f,
  },
});

export type RemoveFilterAction = {
  type: typeof ActionEnum.REMOVE_FILTER,
  payload: string,
  ...
};
export type RemoveFilterFn = typeof removeFilter;
export const removeFilter = (i: string): RemoveFilterAction => ({
  type: ActionEnum.REMOVE_FILTER,
  payload: i,
});

export type AddedFilterAction = {
  type: typeof ActionEnum.ADDED_FILTER,
  payload: {
    name: string,
    value: FilterValue,
    ...
  },
  ...
};
export type AddedFilterFn = typeof addedFilter;
export const addedFilter = (
  name: string,
  f: FilterValue,
): AddedFilterAction => ({
  type: ActionEnum.ADDED_FILTER,
  payload: {
    name: name,
    value: f,
  },
});

export type RemovedFilterAction = {
  type: typeof ActionEnum.REMOVED_FILTER,
  payload: string,
  ...
};
export type RemovedFilterFn = typeof addedFilter;
export const removedFilter = (i: string): RemovedFilterAction => ({
  type: ActionEnum.REMOVED_FILTER,
  payload: i,
});

export type SetGlobalFilterAction = {
  type: typeof ActionEnum.SET_GLOBAL_FILTER,
  payload: {
    id: string,
    value: boolean,
    ...
  },
  ...
};
export type SetGlobalFilterFn = typeof setGlobalFilter;
export const setGlobalFilter = (
  id: string,
  value: boolean,
): SetGlobalFilterAction => ({
  type: ActionEnum.SET_GLOBAL_FILTER,
  payload: {
    id,
    value,
  },
});

export type SetAsCurrentAction = {
  type: typeof ActionEnum.SET_AS_CURRENT_FILTER,
  payload: FilterValue,
  ...
};
export type SetAsCurrentFilterFn = typeof setAsCurrentFilter;
export const setAsCurrentFilter = (f: FilterValue): SetAsCurrentAction => ({
  type: ActionEnum.SET_AS_CURRENT_FILTER,
  payload: f,
});

export type ResetAction = {
  type: typeof ActionEnum.RESET_FILTER,
  payload: null,
  ...
};
export type ResetFn = typeof resetFilter;
export const resetFilter = (): ResetAction => ({
  type: ActionEnum.RESET_FILTER,
  payload: null,
});

export type FiltersLoadedFromServerAction = {
  type: typeof ActionEnum.FILTERS_LOADED_FROM_SERVER,
  payload: SavedFilters,
  ...
};
export type FiltersLoadedFromServerFn = typeof filtersLoadedFromServer;
export const filtersLoadedFromServer = (
  filters: SavedFilters,
): FiltersLoadedFromServerAction => ({
  type: ActionEnum.FILTERS_LOADED_FROM_SERVER,
  payload: filters,
});

export type RequestFiltersFromServerAction = {
  type: typeof ActionEnum.REQUEST_FILTERS_FROM_SERVER,
  payload: null,
  ...
};
export type RequestFiltersFromServerFn = typeof requestFiltersFromServer;
export const requestFiltersFromServer = (): RequestFiltersFromServerAction => ({
  type: ActionEnum.REQUEST_FILTERS_FROM_SERVER,
  payload: null,
});

export type SearchOpenClickedAction = {
  type: typeof ActionEnum.OPEN_BUTTON_CLICKED,
  ...
};
export type SearchOpenClickedFn = typeof searchOpenClicked;
export const searchOpenClicked = (): SearchOpenClickedAction => ({
  type: ActionEnum.OPEN_BUTTON_CLICKED,
});

export type SaveReportClickedFn = typeof saveReportClicked;
export type SaveReportClickedAction = {
  type: typeof ActionEnum.SAVE_REPORT_CLICKED,
  ...
};
export const saveReportClicked = (): SaveReportClickedAction => ({
  type: ActionEnum.SAVE_REPORT_CLICKED,
});
