// @flow
import { createAction } from 'redux-actions';
import type {
  StatusesType,
  KeyStatuses,
  CacheManagerType,
  KeyCache,
} from './types';

export const setStatusesForKeyResourceFetch = createAction<
  'resource_fetching/set_statuses_for_key',
  [string, StatusesType],
  KeyStatuses,
>('resource_fetching/set_statuses_for_key', (key, status: StatusesType) => ({
  key,
  ...status,
}));

export const setCacheStatus = createAction<
  'resource_fetching/cache_manager',
  [string, CacheManagerType],
  KeyCache,
>('resource_fetching/cache_manager', (key, { start, done, error }) => ({
  key,
  start,
  done,
  error,
}));
