// @flow
import { type Saga } from 'redux-saga';
import { type ActionType } from 'redux-actions';
import { policies } from '@dt/horizon-api';

import {
  policiesMounted,
  policiesDetailsMounted,
  policiesCreate,
  policiesCreateSuccess,
  policiesCreateError,
  policiesDeleteButtonClicked,
} from './actions';
import { all, takeEvery } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import {
  takePatternAndFetch,
  handleNormalizedResponse,
  watchForLoadMoreAndFetchNextPage,
  paginateToEnd,
} from '../resource_fetch/sagas';
import {
  getPolicyList,
  getPolicyDetails,
} from '../policies/resource_fetch.sagas';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import resourceType from './resourceType';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(takePatternAndFetch, policiesMounted.toString(), getAllPolicies),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getPolicyList, { cursor });
    }),
    call(takePatternAndFetch, policiesDetailsMounted.toString(), function*(
      action: ActionType<typeof policiesDetailsMounted>,
    ): Saga<void> {
      yield all([call(getPolicyDetails, action.payload.policy_id)]);
    }),

    takeEvery(policiesCreate.toString(), policiesCreateSaga),
    takeEvery(policiesDeleteButtonClicked.toString(), policiesDeleteSaga),
  ]);
}

export const getAllPolicies = function*(): Saga<void> {
  yield call(paginateToEnd, getPolicyList, resourceType, {});
};

function* policiesCreateSaga(
  action: ActionType<typeof policiesCreate>,
): Saga<void> {
  const response = yield* callPromise(policies.create, action.payload);
  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.policies && response.body.policies[0]) {
    yield put(policiesCreateSuccess(response.body.policies[0].id));
  } else {
    yield put(policiesCreateError('Could not add integration'));
  }
}

function* policiesDeleteSaga(
  action: ActionType<typeof policiesDeleteButtonClicked>,
): Saga<void> {
  yield* callPromise(policies.remove, action.payload);
}
