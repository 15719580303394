// @flow
import { type ActionType } from 'redux-actions';
import {
  assetGroupsReceived,
  assetGroupsCreate,
  assetGroupsCreateSuccess,
  assetGroupsCreateError,
  assetGroupsCreateDone,
  assetGroupDeleteClicked,
} from './actions';
import type { AssetGroup } from '@dt/horizon-api';
import immer from 'immer';
import MembershipsReducer, {
  type AssetGroupsMembershipState,
  initialState as initialStateMembership,
  type Actions as ActionsMembership,
} from './memberships/reducer';

export type AssetGroupsState = {|
  ids: { [string]: void | AssetGroup, ... },
  for_policy_id: { [policyId: string]: Array<string>, ... },
  memberships: AssetGroupsMembershipState,
  create: {
    isCreating: boolean,
    isCreatingError: boolean,
    newlyCreatedId: string | null,
    ...
  },
|};

type Actions =
  | ActionsMembership
  | ActionType<typeof assetGroupsReceived>
  | ActionType<typeof assetGroupsCreate>
  | ActionType<typeof assetGroupsCreateSuccess>
  | ActionType<typeof assetGroupsCreateError>
  | ActionType<typeof assetGroupsCreateDone>
  | ActionType<typeof assetGroupDeleteClicked>;

const initialState = {
  ids: {},
  for_policy_id: {},
  memberships: initialStateMembership,
  create: {
    isCreating: false,
    isCreatingError: false,
    newlyCreatedId: null,
  },
};

export default immer<AssetGroupsState, Actions>(
  (draft, action: Actions): void | AssetGroupsState => {
    // $FlowFixMe Not sure what to do
    const newMembershipState = MembershipsReducer(draft.memberships, action);
    if (newMembershipState !== draft.memberships) {
      draft.memberships = newMembershipState;
    }

    switch (action.type) {
      case assetGroupsReceived.toString(): {
        action.payload.forEach(asset => {
          draft.ids[asset.id] = asset;
          const list = draft.for_policy_id[asset.attached_policy_id] || [];

          draft.for_policy_id[asset.attached_policy_id] = list.filter(
            id => id !== asset.id,
          );

          draft.for_policy_id[asset.attached_policy_id].push(asset.id);
        });
        return;
      }
      case assetGroupsCreate.toString(): {
        draft.create.isCreating = true;
        draft.create.isCreatingError = false;
        draft.create.newlyCreatedId = null;
        return;
      }
      case assetGroupsCreateSuccess.toString(): {
        draft.create.isCreating = false;
        draft.create.isCreatingError = false;
        draft.create.newlyCreatedId = action.payload;
        return;
      }
      case assetGroupsCreateError.toString(): {
        draft.create.isCreating = false;
        draft.create.isCreatingError = false;
        draft.create.newlyCreatedId = action.payload;
        return;
      }
      case assetGroupsCreateDone.toString(): {
        draft.create.isCreating = false;
        draft.create.isCreatingError = false;
        draft.create.newlyCreatedId = null;
        return;
      }

      case assetGroupDeleteClicked.toString(): {
        const asset_group = draft.ids[action.payload];

        if (!asset_group) {
          // already deleted?
          return;
        }

        draft.for_policy_id[asset_group.attached_policy_id] = (
          draft.for_policy_id[asset_group.attached_policy_id] || []
        ).filter(id => id !== action.payload);

        draft.ids[action.payload] = void 0;

        return;
      }
    }
  },
  initialState,
);
