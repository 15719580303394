// @flow
import { type Saga } from 'redux-saga';
import { all, call, takeLatest, put, spawn } from 'redux-saga/effects';
import type { ActionType } from 'redux-actions';
import { getNetworkServicesLazily } from './../network_services/sagas';
import { getAllRestfulAPIsLazily } from './../restful_apis/sagas';
import { getAllCloudResourcesLazily } from '../cloud_resources/sagas';
import { takePatternAndFetch } from '../resource_fetch/sagas';
import { getAllOpenAPIDefinitions } from '../openapi_definitions/sagas';
import { getAllWebApplicationsLazily } from '../web_applications/sagas';
import { inventoryRowsRequested } from './actions';
import {
  reachedEndOfNetworkServicesList,
  reachedEndOfNetworkServicesListForAssetSearchId,
} from '../network_services/actions';
import {
  reachedEndOfRestfulApisList,
  reachedEndOfRestfulApisListForAssetSearchId,
} from '../restful_apis/actions';
import {
  reachedEndOfCloudResourcesList,
  reachedEndOfCloudResourcesListForAssetSearchId,
} from '../cloud_resources/actions';
import {
  reachedEndOfWebApplicationsList,
  reachedEndOfWebApplicationsListForAssetSearchId,
} from '../web_applications/actions';

export default function* watchForResourceFetching(): Saga<void> {
  yield spawn(watchForInventoryRequestMore);
  yield call(
    takePatternAndFetch,
    'mounted/inventory*',
    function*(): Saga<void> {
      // Common calls
      const calls = [
        call(getNetworkServicesLazily, {}),
        call(getAllRestfulAPIsLazily, {}),
        call(getAllCloudResourcesLazily, {}),
        call(getAllWebApplicationsLazily, {}),
        call(getAllOpenAPIDefinitions, {}),
      ];

      yield all(calls);
    },
  );
}

function* watchForInventoryRequestMore(): Saga<void> {
  yield takeLatest(inventoryRowsRequested.toString(), function*(
    action: ActionType<typeof inventoryRowsRequested>,
  ): Saga<void> {
    const { search_id } = action.payload;
    if (search_id) {
      yield put(reachedEndOfCloudResourcesListForAssetSearchId(search_id));
      yield put(reachedEndOfNetworkServicesListForAssetSearchId(search_id));
      yield put(reachedEndOfRestfulApisListForAssetSearchId(search_id));
      yield put(reachedEndOfWebApplicationsListForAssetSearchId(search_id));
    } else {
      yield put(reachedEndOfCloudResourcesList());
      yield put(reachedEndOfNetworkServicesList());
      yield put(reachedEndOfRestfulApisList());
      yield put(reachedEndOfWebApplicationsList());
    }
  });
}
