// @flow
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import { values } from '@dt/functions';

import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import {
  type CloudResourcesDecoratedList,
  type CloudResourceDecorated,
} from './types.js';
import { cloudResources, decorate } from './util';
import { type State } from '../store_state_type';
import {
  getCurrentSearchId,
  getResultsOfSearch,
} from '../inventory/search/selectors';
import { type CloudResourcesState } from './reducer';

const idFromProps = (_, props: { +id?: ?string, ... } = {}) => props.id;

export const getCloudResourceFromId = createSelector<
  State,
  { +id: string, ... },
  ?CloudResourceDecorated,
  _,
  _,
  _,
>(
  cloudResources,
  idFromProps,
  getAllTasksWithAffectedComponents,

  (resources, id, policy_violations_decorated) => {
    if (!id) {
      throw new Error('id is required for getCloudResourceFromId selector');
    }

    const resource = resources.id[id];

    if (!resource) {
      return null;
    }

    return decorate(resource, policy_violations_decorated);
  },
);

export const getCloudResourceObj = createObjectSelector<
  State,
  { ... },
  ?CloudResourceDecorated,
  _,
  _,
>(
  state => state.cloud_resources.id,
  getAllTasksWithAffectedComponents,

  (resource, policy_violations_decorated) =>
    decorate(resource, policy_violations_decorated),
);

export const getCloudResources = createSelector<
  State,
  { ... },
  CloudResourcesDecoratedList,
  _,
  _,
>(
  getCloudResourceObj,

  resources => values(resources).filter(Boolean),
);

export const getCloudResourcesForInventory = createSelector<
  State,
  { ... },
  CloudResourcesDecoratedList,
  _,
  _,
  _,
>(
  cloudResources,
  getCurrentSearchId,
  getAllTasksWithAffectedComponents,

  (cloud_resources_state, searchId, policy_violations_decorated) => {
    const cloud_resources = getResultsOfSearch<CloudResourcesState>(
      searchId,
      cloud_resources_state,
    );
    return values(cloud_resources)
      .map(resource => decorate(resource, policy_violations_decorated))
      .filter(Boolean);
  },
);
