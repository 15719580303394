// @flow

import { type Saga } from 'redux-saga';
import { put, all, call, takeEvery } from 'redux-saga/effects';

import { policy_violations } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
// import tracking, { dataCreators } from '@dt/analytics';

import {
  commentInsert,
  commentInsertSuccess,
  commentInsertError,
  commentDeleteSuccess,
  commentDeleteError,
  commentDelete,
} from './actions';
import { type ActionType } from 'redux-actions';
import { getPolicyViolationDetails } from './../resource_fetch.sagas';

function* insertComment(action: ActionType<typeof commentInsert>): Saga<void> {
  const payload = action.payload;

  try {
    if (!payload.policyViolationId) {
      // TODO
      alert('No ID provided!');
      throw new Error('no id provided to policy violation patch');
    }

    const response = yield* callPromise(
      policy_violations.comments.insert,
      payload.policyViolationId,
      payload.params,
    );

    if (!response) {
      // TODO
      throw new Error('response error');
    }

    if (typeof response.title === 'string') {
      throw new Error(response.title);
    }

    yield call(getPolicyViolationDetails, payload.policyViolationId, {
      forceUpdate: true,
    });

    // yield call(
    //   tracking,
    //   dataCreators.policyViolationExceptionChange(params.exception_type),
    // );

    yield put(commentInsertSuccess());
  } catch (e) {
    yield put(commentInsertError(e.message));
  }
}

function* deleteComment(action: ActionType<typeof commentDelete>): Saga<void> {
  const { policyViolationId, commentId } = action.payload;

  try {
    if (!policyViolationId) {
      // TODO
      alert('No ID provided!');
      throw new Error('no id provided to policy violation patch');
    }

    const response = yield* callPromise(
      policy_violations.comments.delete,
      policyViolationId,
      commentId,
    );

    if (!response) {
      // TODO
      throw new Error('response error');
    }

    if (typeof response.ok === 'boolean' && !response.ok) {
      throw new Error('There was a problem making the request');
    }

    if (typeof response.title === 'string') {
      throw new Error(response.title);
    }

    // yield call(
    //   tracking,
    //   dataCreators.policyViolationExceptionChange(params.exception_type),
    // );

    yield call(getPolicyViolationDetails, policyViolationId, {
      forceUpdate: true,
    });

    yield put(commentDeleteSuccess(commentId));
  } catch (e) {
    yield put(commentDeleteError(commentId, e.message));
  }
}
export default function* watchForAll(): Saga<void> {
  yield all([
    takeEvery(commentDelete.toString(), deleteComment),
    takeEvery(commentInsert.toString(), insertComment),
  ]);
}
