// @flow
import { type Saga } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import {
  policy_violations,
  type PolicyViolationsListParams,
} from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';

import resourceType from './resourceType';
import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';

export const getPolicyViolationList = withCache<PolicyViolationsListParams>(
  'policy_violations_list',
  function* getPolicyViolationList(
    params: PolicyViolationsListParams,
  ): Saga<void> {
    yield put(paginationBeginRequest(resourceType, params || {}));

    const response = yield* callPromise(
      policy_violations.list,
      params ? params : {},
    );
    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);

    if (response.body.pagination_information) {
      yield put(
        paginationEndRequest(
          resourceType,
          params || {},
          response.body.pagination_information,
        ),
      );
    }
  },
);

export const getPolicyViolationDetails = withCache<string>(
  (policy_violation_id: string) => `policy_violation_${policy_violation_id}`,
  function* getPolicyViolationDetails(policy_violation_id: string): Saga<void> {
    const response = yield* callPromise(
      policy_violations.details,
      policy_violation_id,
    );

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
