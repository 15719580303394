import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Grid, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import format from 'date-fns/format';
import differenceInCalendarDays from 'date-fns/difference_in_calendar_days';
import isAfter from 'date-fns/is_after';
import useCookie from '../hooks/use_cookie';
function safeParse(value) {
    try {
        return value ? JSON.parse(value) : null;
    }
    catch (e) {
        console.error(e);
        return null;
    }
}
var useStyles = makeStyles({
    snackbar: {
        width: '80%',
    },
});
function TemporaryBroadcastMessage() {
    var classes = useStyles();
    var BROADCAST_MESSAGE = {
        message: "Welcome to the new Mobile Secure Interface.",
        expires: '2023-04-21',
        hash: 'c7fa6ff47492e011a686218193275811',
    };
    var _a = useState(false), dismissed = _a[0], setDismissed = _a[1];
    var expiryDate = useMemo(function () {
        return format(new Date(BROADCAST_MESSAGE.expires));
    }, [BROADCAST_MESSAGE.expires]);
    var daysToExpiry = useMemo(function () {
        return differenceInCalendarDays(expiryDate, new Date());
    }, [expiryDate]);
    var cookieKey = useMemo(function () {
        return BROADCAST_MESSAGE.hash ? "DT-".concat(BROADCAST_MESSAGE.hash) : '';
    }, [BROADCAST_MESSAGE.hash]);
    var _b = useCookie(cookieKey), broadcastCookie = _b[0], setBroadcastCookie = _b[1];
    // No existing cookie for the current broadcast message
    useEffect(function () {
        if (typeof broadcastCookie === 'undefined') {
            var cookieValue = JSON.stringify({
                dismissed: false,
                expires: expiryDate,
            });
            daysToExpiry > 0 ? setBroadcastCookie(cookieValue, { expires: daysToExpiry }) : setBroadcastCookie(cookieValue);
        }
    }, [broadcastCookie, cookieKey, BROADCAST_MESSAGE.expires, expiryDate, setBroadcastCookie, daysToExpiry]);
    var broadcastCookieJSON = useMemo(function () {
        return safeParse(broadcastCookie);
    }, [broadcastCookie]);
    var dismissedOrExpiredCookie = useMemo(function () {
        return broadcastCookieJSON
            ? broadcastCookieJSON.dismissed || isAfter(format(new Date()), broadcastCookieJSON.expires)
            : false;
    }, [broadcastCookieJSON]);
    var handleDismissBroadcastMessage = useCallback(function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setDismissed(true);
        // Update the cookie to reflect that the message should be permanently dismissed
        var cookieValue = JSON.stringify({
            dismissed: true,
            expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
        });
        setBroadcastCookie(cookieValue, {
            expires: daysToExpiry > 0 ? daysToExpiry : 0,
        });
    }, [setBroadcastCookie, broadcastCookie, daysToExpiry]);
    if (dismissed || dismissedOrExpiredCookie || daysToExpiry <= 0) {
        return null;
    }
    if (!BROADCAST_MESSAGE.message) {
        return null;
    }
    return (React.createElement(Snackbar, { className: classes.snackbar, open: !dismissed, onClose: handleDismissBroadcastMessage, anchorOrigin: { vertical: 'top', horizontal: 'center' } },
        React.createElement(Alert, { severity: "success", variant: "standard", onClose: handleDismissBroadcastMessage, action: React.createElement(Grid, { container: true, direction: 'row', justify: 'flex-end' },
                React.createElement(Button, { "aria-label": 'Dismiss', variant: "outlined", size: 'small', color: "primary", onClick: handleDismissBroadcastMessage }, "Awesome")) }, BROADCAST_MESSAGE.message)));
}
export default TemporaryBroadcastMessage;
