// @flow
import { type ActionType } from 'redux-actions';
import { sharedLinksReceived, sharedLinksDeleteSuccess } from './actions';
import type { SharedLinks } from '@dt/horizon-api';
import immer from 'immer';

export type SharedLinksState = {
  id: { [shared_link_id: string]: void | SharedLinks, ... },
};

const initialState = {
  id: {},
};

type Actions =
  | ActionType<typeof sharedLinksReceived>
  | ActionType<typeof sharedLinksDeleteSuccess>;

export default immer<SharedLinksState, Actions>((draft, action: Actions) => {
  if (action.type === sharedLinksReceived.toString()) {
    for (let shared_link of action.payload) {
      draft.id[shared_link.id] = shared_link;
    }
  }

  if (action.type === sharedLinksDeleteSuccess.toString()) {
    draft.id[action.payload] = void 0;
  }
}, initialState);
