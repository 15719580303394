// @flow
import { type Saga } from 'redux-saga';
import { select } from '@dt/redux-saga-wrapped-effects';
import {
  webApplicationDetailsMounted,
  reachedEndOfWebApplicationsList,
  reachedEndOfWebApplicationsListForAssetSearchId,
  webApplicationsReceived,
  webApplicationsReceivedIdsForAssetSearch,
} from './actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { type ActionType } from 'redux-actions';
import {
  getWebApplicationDetails,
  getWebApplicationsList,
} from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateToEndLazy,
} from '../resource_fetch/sagas';
import resourceType from './resourceType';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { inventory_search } from '../inventory/search/selectors';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      webApplicationDetailsMounted.toString(),
      function*(
        action: ActionType<typeof webApplicationDetailsMounted>,
      ): Saga<void> {
        const webApplicationId = action.payload.webApplicationId;
        yield call(getWebApplicationDetails, webApplicationId);
      },
    ),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getWebApplicationsList, { cursor });
    }),
    takeEvery(webApplicationsReceived.toString(), webApplicationsReceivedSaga),
  ]);
}

export function* getAllWebApplicationsLazily(params: {
  search_id?: string,
  cursor?: string,
}): Saga<void> {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getWebApplicationsList,
      resourceType,
      { ...params },
      action =>
        reachedEndOfWebApplicationsListForAssetSearchId.toString() &&
        params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getWebApplicationsList,
      resourceType,
      { ...params },
      reachedEndOfWebApplicationsList.toString(),
    );
  }
}

function* webApplicationsReceivedSaga(
  action: ActionType<typeof webApplicationsReceived>,
): Saga<void> {
  const { web_applications } = action.payload;
  const { lastQueryId, query } = yield* select(inventory_search, {});

  let assetGroupMembers = [];
  web_applications.map(item => {
    if (item.asset_group_memberships) {
      assetGroupMembers = assetGroupMembers.concat(
        item.asset_group_memberships,
      );
    }
  });

  const collector = web_applications.reduce(
    (collector, item) => {
      if (item.asset_group_memberships) {
        collector.assetGroupMembers = collector.assetGroupMembers.concat(
          item.asset_group_memberships,
        );
      }
      collector.idsForCurrentSearch.push(item.id);
      return collector;
    },
    {
      assetGroupMembers: [],
      idsForCurrentSearch: [],
    },
  );

  if (collector.assetGroupMembers) {
    yield put(assetGroupsMembershipReceived(collector.assetGroupMembers));
  }

  if (
    lastQueryId &&
    (!query.asset_types.length || query.asset_types.includes('WEB_APPLICATION'))
  ) {
    yield put(
      webApplicationsReceivedIdsForAssetSearch(
        lastQueryId,
        collector.idsForCurrentSearch,
      ),
    );
  }
}
