// @flow
import immer from 'immer';
import type { CloudResource } from '@dt/horizon-api';
import type { ActionType } from 'redux-actions';
import {
  cloudResourcesReceived,
  cloudResourcesReceivedIdsForAssetSearch,
} from './actions';

type Actions =
  | ActionType<typeof cloudResourcesReceived>
  | ActionType<typeof cloudResourcesReceivedIdsForAssetSearch>;

export type CloudResourcesState = {|
  +id: { [string]: void | CloudResource, ... },
  +for_policy_violation_id: { [string]: void | string, ... },
  +for_search_id: { [string]: Array<string>, ... },
|};

const initialState = {
  id: {},
  for_policy_violation_id: {},
  for_search_id: {},
};

export default function(
  state: CloudResourcesState = initialState,
  action: Actions,
): CloudResourcesState {
  if (action.type === cloudResourcesReceived.toString()) {
    return immer(state, draft => {
      const { cloud_resources } = action.payload;
      for (const item of cloud_resources) {
        draft.id[item.id] = item;
        for (const policy_violation_id of item.policy_violation_ids) {
          draft.for_policy_violation_id[policy_violation_id] = item.id;
        }
      }
      return draft;
    });
  } else if (
    action.type === cloudResourcesReceivedIdsForAssetSearch.toString()
  ) {
    return immer(state, draft => {
      const { searchId, ids } = action.payload;
      draft.for_search_id[searchId] = (
        draft.for_search_id[searchId] || []
      ).concat(ids);
    });
  } else {
    return state;
  }
}
