//@flow
import React, { type Node } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TooltipIcon from '@material-ui/icons/HelpOutline';
import Tooltip from '@material-ui/core/Tooltip';

import { palette, fontSizes } from '@dt/theme';

import Markdown from './Markdown';
import AndroidImage from './platform_images/android_icon.png';
import IOSImage from './platform_images/ios_icon.png';

const useStyles = makeStyles({
  InfoCard: {
    wordBreak: 'break-all !important',
    backgroundSize: 'contain !important',
    position: 'relative !important',
    fontSize: fontSizes.medium,
    minHeight: 110,
    minWidth: 110,
  },

  // Following class acts as an overlay.
  heading: {
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${palette.gray45}`,
    justifyContent: 'space-between',
    backgroundColor: ({ attention }: Props) =>
      attention ? palette.red20 : palette.white,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
  },

  primaryText: {
    padding: 10,
    fontSize: ({ fontSize, primaryText }: Props) =>
      fontSize ||
      (primaryText && primaryText.length > 15
        ? fontSizes.medium
        : fontSizes.large),
  },

  icon: {
    maxWidth: '24px',
    maxHeight: '24px',
  },

  img: {
    maxWidth: '100%',
  },

  tooltipIcon: {
    color: ({ attention }) =>
      attention
        ? `${palette.white} !important`
        : `${palette.gray30} !important`,
  },

  headingText: {
    verticalAlign: 'middle',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
    color: ({ attention }: Props) => (attention ? palette.white : null),
  },

  headingIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },

  tooltip: {
    maxWidth: '300px !important',
    padding: '20px !important',
    borderRadius: '5px !important',
  },

  inner: {
    position: 'relative',
  },
});

type Props = {|
  heading: string,
  primaryText?: ?string,
  list?: ?any,
  android?: ?boolean,
  ios?: ?boolean,
  tooltip?: ?string,
  attention?: ?boolean,
  fontSize?: ?number,
  children?: Node,
|};

export default React.memo<Props>(function InfoCard(props) {
  const { heading, primaryText, list, android, ios, tooltip, children } = props;
  const classes = useStyles(props);

  return (
    <Paper className={classes.InfoCard}>
      <div className={classes.heading}>
        <div className={classes.headingText}>{heading}</div>
        <div className={classes.headingIcon}>
          {tooltip && !ios && !android && (
            <Tooltip title={tooltip}>
              <span className={classes.tooltipIcon}>
                <TooltipIcon className={classes.tooltipIcon} color="inherit" />
              </span>
            </Tooltip>
          )}
          {(ios || android) && (
            <Tooltip title={ios ? 'iOS' : 'Android'}>
              <div className={classes.icon}>
                <img
                  className={classes.img}
                  src={ios ? IOSImage : AndroidImage}
                />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.inner}>
        {primaryText && (
          <div className={classes.primaryText}>
            <Markdown inline text={primaryText} />
          </div>
        )}
        {children}
        {list && (
          <List disablePadding>
            {list.map(item => (
              <div key={item}>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Markdown inline text={item} />
                  </ListItemText>
                </ListItem>
              </div>
            ))}
          </List>
        )}
      </div>
    </Paper>
  );
});
