// @flow
import { handleActions, type ActionType } from 'redux-actions';
import {
  awsAuthenticatorsReceived,
  gcpAuthenticatorsReceived,
  azureAuthenticatorsReceived,
  axwayAuthenticatorsReceived,
  mulesoftAuthenticatorsReceived,
  apigeeAuthenticatorsReceived,
  certificateTransparencyReceived,
  kongAuthenticatorsReceived,
} from './actions';
import { type CertificateTransparencyDomains } from '@dt/horizon-api';
import type {
  AwsAuthenticatorList,
  GcpAuthenticatorList,
  AzureAuthenticatorList,
  AxwayAuthenticatorList,
  MulesoftAuthenticatorList,
  ApigeeAuthenticatorList,
  KongAuthenticatorsList,
} from './types';

export type ConfigurationsState = {|
  aws_authenticators_list: AwsAuthenticatorList,

  certificate_transparency: null | CertificateTransparencyDomains,

  gcp_authenticators_list: GcpAuthenticatorList,

  azure_authenticators_list: AzureAuthenticatorList,

  axway_authenticators_list: AxwayAuthenticatorList,

  mulesoft_authenticators_list: MulesoftAuthenticatorList,

  kong_authenticators_list: KongAuthenticatorsList,

  apigee_authenticators_list: ApigeeAuthenticatorList,
|};

const initialState: ConfigurationsState = {
  aws_authenticators_list: [],

  certificate_transparency: null,

  gcp_authenticators_list: [],

  azure_authenticators_list: [],

  axway_authenticators_list: [],

  mulesoft_authenticators_list: [],

  kong_authenticators_list: [],

  apigee_authenticators_list: [],
};

export default handleActions<ConfigurationsState, any>(
  {
    [awsAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof awsAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.aws_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.role_arn,
          type: 'aws',
        })),
      ];
      return {
        ...state,
        aws_authenticators_list: newList,
      };
    },
    [gcpAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof gcpAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.gcp_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.client_email,
          type: 'gcp',
        })),
      ];
      return {
        ...state,
        gcp_authenticators_list: newList,
      };
    },
    [azureAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof azureAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.azure_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.client_id,
          type: 'azure',
        })),
      ];
      return {
        ...state,
        azure_authenticators_list: newList,
      };
    },
    [axwayAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof axwayAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.axway_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization_id,
          type: 'axway',
        })),
      ];
      return {
        ...state,
        axway_authenticators_list: newList,
      };
    },
    [mulesoftAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof mulesoftAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.mulesoft_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization_id,
          type: 'mulesoft',
        })),
      ];
      return {
        ...state,
        mulesoft_authenticators_list: newList,
      };
    },
    [kongAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof kongAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.kong_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.kong_admin_subdomain,
          type: 'kong',
        })),
      ];
      return {
        ...state,
        kong_authenticators_list: newList,
      };
    },
    [apigeeAuthenticatorsReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof apigeeAuthenticatorsReceived>,
    ) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.apigee_authenticators_list.filter(
          ({ id }) => !newIds.includes(id),
        ),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization,
          type: 'apigee',
        })),
      ];
      return {
        ...state,
        apigee_authenticators_list: newList,
      };
    },
    [certificateTransparencyReceived.toString()]: (
      state: ConfigurationsState,
      action: ActionType<typeof certificateTransparencyReceived>,
    ) => ({
      ...state,
      certificate_transparency: action.payload,
    }),
  },
  initialState,
);
