// @flow
import React from 'react';
import {
  PolicyRuleTypeRelevance,
  type PolicyRuleTypeRelevanceType,
} from '@dt/horizon-api';

import WarningIcon from '@material-ui/icons/Warning';
import ImportantWarningIcon from '@material-ui/icons/Flag';
import InformationalProactiveIcon from '@material-ui/icons/Info';

import { palette } from '@dt/theme';
import { Chip, withStyles, type WithStyles } from '@material-ui/core';

type OwnProps = {|
  relevance: PolicyRuleTypeRelevanceType,
  noLabel?: boolean,
  size?: 'medium' | 'small',
|};

type Props = {| ...OwnProps, ...WithStyles |};

const styles = {
  [PolicyRuleTypeRelevance.URGENT]: {
    backgroundColor: palette.red50,
    color: palette.red30,
  },
  [PolicyRuleTypeRelevance.IMPORTANT]: {
    backgroundColor: palette.yellow50,
    color: palette.yellow10,
  },
  [PolicyRuleTypeRelevance.PROACTIVE]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
  },
  noLabel: {
    paddingLeft: 0,
  },
};

const RelevanceTag = ({ relevance, noLabel, size, classes }: Props) => {
  const Icon =
    relevance === 'URGENT'
      ? WarningIcon
      : relevance === 'IMPORTANT'
      ? ImportantWarningIcon
      : InformationalProactiveIcon;

  const chipSize = size ? size : 'small';
  const iconSize = chipSize === 'small' ? 20 : 24;

  return (
    <Chip
      icon={
        <Icon
          style={{ ...styles[relevance], fontSize: iconSize, paddingLeft: 2 }}
        />
      }
      label={noLabel ? null : PolicyRuleTypeRelevance[relevance]}
      size={chipSize}
      classes={{ root: classes[relevance], label: noLabel && classes.noLabel }}
      style={{
        borderRadius: 4,
      }}
    />
  );
};

export default React.memo<OwnProps>(withStyles(styles)(RelevanceTag));
