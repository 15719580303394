//@flow
import React, { memo } from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import ImportantWarningIcon from '@material-ui/icons/Flag';
import InformationalProactiveIcon from '@material-ui/icons/Info';
import { palette } from '@dt/theme';

type Props = {|
  //we don't use the horizon-api package for this because it should be generic
  relevance: 'URGENT' | 'IMPORTANT' | 'PROACTIVE',
|};

const MiniRelevanceIcon = ({ relevance }: Props) => {
  const Icon =
    relevance === 'URGENT'
      ? WarningIcon
      : relevance === 'IMPORTANT'
      ? ImportantWarningIcon
      : InformationalProactiveIcon;
  const backgroundColor =
    relevance === 'URGENT'
      ? palette.red50
      : relevance === 'IMPORTANT'
      ? palette.yellow50
      : palette.blue50;
  const iconColor =
    relevance === 'URGENT'
      ? palette.red
      : relevance === 'IMPORTANT'
      ? palette.yellow
      : palette.blue;

  return (
    <div
      style={{
        display: 'inline-block',
        backgroundColor,
        borderRadius: 2,
        padding: 4,
        fontSize: 0,
        width: 20,
        height: 20,
      }}
    >
      <Icon style={{ color: iconColor, fontSize: 12 }} fontSize="small" />
    </div>
  );
};

export default memo<Props>(MiniRelevanceIcon);
