import React from 'react';
// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import { Button } from '@material-ui/core';
import NavigationItemContent from './NavigationItemContent';
var NavigationItem = function (_a) {
    var baseUrl = _a.baseUrl, label = _a.label, to = _a.to, Icon = _a.Icon, _b = _a.isSvg, isSvg = _b === void 0 ? false : _b, _c = _a.isButton, isButton = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.tooltip, tooltip = _e === void 0 ? false : _e, onClick = _a.onClick;
    var location = useLocation();
    var isCurrent = false;
    if (baseUrl && location.pathname.indexOf(baseUrl) > -1) {
        var condition = true;
        if (baseUrl.includes('devsecops') && location.pathname.includes('stanford-dish-security-university')) {
            condition = baseUrl.includes('stanford-dish-security-university');
        }
        isCurrent = condition;
    }
    if (isButton) {
        return (React.createElement(Button, { style: {
                background: 'none',
                border: 'none',
                padding: 0,
                margin: 0,
                cursor: 'pointer',
                minWidth: 0,
            }, disabled: disabled, onClick: onClick },
            React.createElement(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, tooltip: tooltip })));
    }
    if (disabled) {
        return (React.createElement(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, disabled: true, tooltip: tooltip }));
    }
    return (React.createElement(Link, { to: disabled ? '' : to },
        React.createElement(NavigationItemContent, { isCurrent: isCurrent, label: label, Icon: Icon, isSvg: isSvg, tooltip: tooltip })));
};
export default NavigationItem;
