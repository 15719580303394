// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import {
  inventoryExportClicked,
  inventoryExportCancelClicked,
  inventoryExportCompleted,
  inventoryExportResetSagaRequestsClicked,
  inventoryExportRequestRow,
  inventoryExportRestartRequestRow,
} from './actions';

export type InventoryExportState = {|
  inProgress: boolean,
  resetSagaRequest: boolean,
|};

type Actions =
  | ActionType<typeof inventoryExportClicked>
  | ActionType<typeof inventoryExportCancelClicked>
  | ActionType<typeof inventoryExportCompleted>
  | ActionType<typeof inventoryExportResetSagaRequestsClicked>
  | ActionType<typeof inventoryExportRequestRow>
  | ActionType<typeof inventoryExportRestartRequestRow>;

const initialState = {
  inProgress: false,
  resetSagaRequest: false,
};

export default immer<InventoryExportState, Actions>(
  (draft, action: Actions) => {
    if (action.type === inventoryExportClicked.toString()) {
      draft.inProgress = true;
    }

    if (action.type === inventoryExportCancelClicked.toString()) {
      draft.inProgress = false;
      draft.resetSagaRequest = false;
    }

    if (action.type === inventoryExportCompleted.toString()) {
      draft.inProgress = false;
    }

    if (action.type === inventoryExportResetSagaRequestsClicked.toString()) {
      draft.resetSagaRequest = true;
    }

    if (action.type === inventoryExportRequestRow.toString()) {
      draft.resetSagaRequest = false;
    }

    if (action.type === inventoryExportRestartRequestRow.toString()) {
      draft.resetSagaRequest = false;
    }
  },
  initialState,
);
