// @flow
import { createSelector } from 'reselect';
import type { OpenscanMobileApp } from '@dt/user-api/openscan';
import { type SearchResults } from '@dt/user-api/openscan';
import { type State } from '../../store_state_type';

type OpenscanMobileApps = $ReadOnlyArray<OpenscanMobileApp>;

export const config = (state: State) => state.openscanConfig;

const apps = (state: State): OpenscanMobileApps => state.openscanApps;

export const appFromId = (appId: string) => {
  return createSelector<State, { ... }, void | OpenscanMobileApp, _>(
    apps,
    apps => apps.find(app => app.id === appId),
  );
};

export const watchlistApps = createSelector<
  State,
  { ... },
  OpenscanMobileApps | null,
  _,
>(apps, apps => apps && apps.filter(app => app.favorite));

export type AppIdParam = {
  +appId: string | void,
  ...
};

export const appFromParam = createSelector<
  State,
  AppIdParam,
  ?OpenscanMobileApp,
  _,
  _,
>(
  (state, props) => props.appId,
  apps,

  (id, apps) => {
    return apps && apps.find(app => app.id === id);
  },
);

type AppNameParam = {
  +appName: string | void,
  ...
};

export const searchCriteria = (state: State, props: AppNameParam) =>
  props.appName;

const searchResults = (state: State) => state.searchResults;

export const activeSearchResults = createSelector<
  State,
  AppNameParam,
  SearchResults,
  _,
  _,
>(searchCriteria, searchResults, (searchCriteria, searchResults) => {
  if (typeof searchCriteria !== 'string') {
    return [];
  }

  const searchCriteriaDecoded = decodeURIComponent(searchCriteria).replace(
    /\+/g,
    ' ',
  );

  return searchResults[searchCriteriaDecoded] || [];
});
