// @flow
import {
  type EventsList,
  type Event as HorizonEvent,
  type UsersList,
  type CommentsList,
} from '@dt/horizon-api';

import { type State } from '../store_state_type';
import { type PolicyViolationForEventList } from '../policy_violations/types';
import { type NetworkServicesDecoratedList } from '../network_services/types';
import { type RestfulAPIDecoratedList } from '../restful_apis/types';
import { type CloudResourcesDecoratedList } from '../cloud_resources/types';
import { type WebApplicationDecoratedList } from '../web_applications/types';
import type {
  AwsAuthenticatorList,
  GcpAuthenticatorList,
  AzureAuthenticatorList,
  AxwayAuthenticatorList,
  MulesoftAuthenticatorList,
  ApigeeAuthenticatorList,
} from '../configurations/types';

import type { Event } from './types';

import getDescription from './event_descriptions';

export const events = ({ events }: State): EventsList => events;

export const decorate = (
  event: HorizonEvent,
  network_services_decorated: NetworkServicesDecoratedList,
  restful_apis_decorated: RestfulAPIDecoratedList,
  cloud_resources_decorated: CloudResourcesDecoratedList,
  policy_violations_decorated: PolicyViolationForEventList,
  web_applications_decorated: WebApplicationDecoratedList,
  aws_authenticators: AwsAuthenticatorList,
  gcp_authenticators: GcpAuthenticatorList,
  azure_authenticators: AzureAuthenticatorList,
  axway_authenticators: AxwayAuthenticatorList,
  mulesoft_authenticators: MulesoftAuthenticatorList,
  apigee_authenticators: ApigeeAuthenticatorList,
  users: UsersList,
  comments: CommentsList,
): ?Event => {
  let user;
  if (event.user_id) {
    user = users.find(u => u.id === event.user_id);
  }

  let policy_violation_decorated;
  if (event.policy_violation_id) {
    policy_violation_decorated = policy_violations_decorated.find(
      task => task.id === event.policy_violation_id,
    );
  }

  let network_service_decorated;
  if (event.network_service_id) {
    network_service_decorated = network_services_decorated.find(
      n => n.id === event.network_service_id,
    );
  }

  let restful_api_decorated;
  if (event.restful_api_id) {
    restful_api_decorated = restful_apis_decorated.find(
      r => r.id === event.restful_api_id,
    );
  }

  let api_operation;
  if (event.api_operation_id) {
    restful_api_decorated = restful_apis_decorated.find(r =>
      r.api_operations.find(a => a.id === event.api_operation_id),
    );
    if (restful_api_decorated) {
      api_operation = restful_api_decorated.api_operations.find(
        a => a.id === event.api_operation_id,
      );
    }
  }

  let cloud_resource_decorated;
  if (event.cloud_resource_id) {
    cloud_resource_decorated = cloud_resources_decorated.find(
      task => task.id === event.cloud_resource_id,
    );
  }

  let aws_authenticator;
  if (event.aws_authenticator_id) {
    aws_authenticator = aws_authenticators.find(
      aws => aws.id === event.aws_authenticator_id,
    );
  }

  let gcp_authenticator;
  if (event.gcp_authenticator_id) {
    gcp_authenticator = gcp_authenticators.find(
      gcp => gcp.id === event.gcp_authenticator_id,
    );
  }

  let azure_authenticator;
  if (event.azure_authenticator_id) {
    azure_authenticator = azure_authenticators.find(
      azure => azure.id === event.azure_authenticator_id,
    );
  }

  let axway_authenticator;
  if (event.axway_authenticator_id) {
    axway_authenticator = axway_authenticators.find(
      axway => axway.id === event.axway_authenticator_id,
    );
  }

  let mulesoft_authenticator;
  if (event.mulesoft_authenticator_id) {
    mulesoft_authenticator = mulesoft_authenticators.find(
      mulesoft => mulesoft.id === event.mulesoft_authenticator_id,
    );
  }

  let apigee_authenticator;
  if (event.apigee_authenticator_id) {
    apigee_authenticator = apigee_authenticators.find(
      apigee => apigee.id === event.apigee_authenticator_id,
    );
  }

  let web_application_decorated;
  if (event.web_application_id) {
    web_application_decorated = web_applications_decorated.find(
      webapp => webapp.id === event.web_application_id,
    );
  }

  let comment;
  if (event.comment_id) {
    comment = comments.find(comment => comment.id === event.comment_id);
    if (comment) {
      const user_id = comment.added_by_user_id;
      user = users.find(u => u.id === user_id);
    }
  }

  const event_decorated = {
    ...event,
    comment: comment
      ? {
          ...comment,
          date_created: new Date(comment.date_created),
        }
      : null,
    date_created: new Date(event.date_created),
    cloud_resource: cloud_resource_decorated,
    network_service: network_service_decorated,
    policy_violation: policy_violation_decorated
      ? {
          ...policy_violation_decorated,
          violated_policy_rule:
            policy_violation_decorated.policy_rule_decorated,
        }
      : null,
    restful_api: restful_api_decorated,
    web_application: web_application_decorated,
    user,
    api_operation,
    aws_authenticator,
    gcp_authenticator,
    azure_authenticator,
    axway_authenticator,
    mulesoft_authenticator,
    apigee_authenticator,
  };

  if (
    getDescription({
      event: event_decorated,
    }) === ''
  ) {
    return null;
  }

  return event_decorated;
};
