// @flow
import { type State } from '../../store_state_type';
import { createSelector } from 'reselect';
import { AssetTypes } from '@dt/horizon-api';
import { values } from '@dt/functions';
import { type CloudResourcesState } from '../../cloud_resources/reducer';
import { type WebApplicationState } from '../../web_applications/reducer';
import { type NetworkServiceState } from '../../network_services/reducer';
import { type RestfulAPIsState } from '../../restful_apis/reducer';

export const inventory_search = ({ inventory_search }: State) =>
  inventory_search;

export const getCurrentSearchId = ({ inventory_search }: State) =>
  inventory_search.lastQueryId;

const inventorySearchAssetTypes = ({ inventory_search }: State) =>
  inventory_search.asset_types;

const inventorySearchCloudResourcesTypes = ({ inventory_search }: State) =>
  inventory_search.cloud_resource_types;

const inventorySearchCloudProviderList = ({ inventory_search }: State) =>
  inventory_search.cloudProviderList;

const inventorySearchQuery = ({ inventory_search }: State) =>
  inventory_search.query;

type AssetStates =
  | RestfulAPIsState
  | NetworkServiceState
  | WebApplicationState
  | CloudResourcesState;

export type AssetListType = {|
  name: string,
  id: string,
  selected?: boolean,
  anySelected?: boolean,
  allSelected?: boolean,
  icon?: string,
  children?: Array<AssetListType>,
|};

export const getResultsOfSearch = <T: AssetStates>(
  lastSearchId: ?string,
  state: T,
): $PropertyType<T, 'id'> => {
  // If there is a search, return the filtered list. Otherwise, return all the elements
  return lastSearchId
    ? (state.for_search_id[lastSearchId] || []).reduce((ids, foundId) => {
        ids[foundId] = state.id[foundId];
        return ids;
      }, {})
    : state.id;
};

export const getInventorySearchAssetList = createSelector<
  State,
  { ... },
  Array<AssetListType>,
  _,
  _,
  _,
>(
  inventorySearchAssetTypes,
  inventorySearchCloudResourcesTypes,
  inventorySearchQuery,
  (assetTypes, cloudResourcesTypes, query) => {
    return assetTypes.reduce((assetList, asset) => {
      if (
        asset.enum === AssetTypes.CLOUD_RESOURCE &&
        cloudResourcesTypes.for_category
      ) {
        const catgeories = values(cloudResourcesTypes.for_category).reduce(
          (categories, category) => {
            const categoryChildren = category.ids.reduce(
              (cloudResourceTypesForCategory, cloudResourceTypeId) => {
                cloudResourceTypesForCategory.push({
                  name: cloudResourcesTypes.ids[cloudResourceTypeId].name,
                  id: cloudResourcesTypes.ids[cloudResourceTypeId].enum,
                  icon: cloudResourcesTypes.ids[cloudResourceTypeId].icon_url,
                  selected:
                    (query.cloud_resource_types &&
                      Boolean(
                        query.cloud_resource_types.includes(
                          cloudResourcesTypes.ids[cloudResourceTypeId].enum,
                        ),
                      )) ||
                    false,
                });
                return cloudResourceTypesForCategory;
              },
              [],
            );
            const anySelected = Boolean(
              categoryChildren.filter(item => item.selected).length,
            );
            const allSelected = Boolean(
              !categoryChildren.filter(item => !item.selected).length,
            );
            if (category.enum === 'OTHER') {
              categories.push({
                name: category.name,
                id: category.enum,
                anySelected,
                allSelected: Boolean(anySelected && allSelected),
                children: categoryChildren,
              });
            } else {
              categories.unshift({
                name: category.name,
                id: category.enum,
                anySelected,
                allSelected: Boolean(anySelected && allSelected),
                children: categoryChildren,
              });
            }
            return categories;
          },
          [],
        );
        const anyChildrenSelected = Boolean(
          catgeories.filter(item => item.anySelected).length,
        );
        const allChildrenSelected = Boolean(
          !catgeories.filter(item => !item.allSelected).length,
        );
        assetList.push({
          name: asset.name,
          id: asset.enum,
          anySelected: anyChildrenSelected,
          allSelected: allChildrenSelected,
          children: catgeories,
        });
      } else {
        assetList.push({
          name: asset.name,
          id: asset.enum,
          selected: Boolean(
            query.asset_types && query.asset_types.includes(asset.enum),
          ),
        });
      }

      return assetList;
    }, []);
  },
);

export const getInventorySearchEnvironmentsList = createSelector<
  State,
  { ... },
  Array<{|
    id: string,
    name: string,
    selected: boolean,
  |}>,
  _,
  _,
  _,
>(
  inventory_search,
  inventorySearchQuery,
  inventorySearchQuery,
  (inventory_search, query) => {
    return inventory_search.cloudProviderList.reduce((list, provider) => {
      return list.concat({
        id: provider.enum,
        name: provider.name,
        selected:
          query.hosted_on_cloud_providers &&
          query.hosted_on_cloud_providers.includes(provider.enum)
            ? true
            : false,
      });
    }, []);
  },
);

export const getSearchedText = createSelector<State, { ... }, void, _, _>(
  inventory_search,
  () => {},
);

export const getSelectedAssetsCount = createSelector<
  State,
  { ... },
  'all' | 'any' | number,
  _,
  _,
  _,
>(
  inventorySearchAssetTypes,
  inventorySearchCloudResourcesTypes,
  inventorySearchQuery,
  (assetTypes, cloudResourcesTypes, query) => {
    const totalCloudResourceTypes = cloudResourcesTypes.ids.length;
    const totalSelectedCloudTypes = query.cloud_resource_types.length;

    const totalAssetTypes = assetTypes.length;
    const totalSelectedAssets = query.asset_types && query.asset_types.length;

    if (totalSelectedCloudTypes + totalSelectedAssets === 0) {
      return 'any';
    } else if (
      totalCloudResourceTypes === totalSelectedCloudTypes &&
      totalAssetTypes === totalSelectedAssets
    ) {
      return 'all';
    } else if (totalSelectedAssets && totalSelectedCloudTypes === 0) {
      return totalSelectedAssets;
    } else if (totalSelectedAssets && totalSelectedCloudTypes !== 0) {
      return Number(totalSelectedAssets) - 1 + totalSelectedCloudTypes;
    }

    return 'any';
  },
);

export const getSelectedEnvironmentsCount = createSelector<
  State,
  { ... },
  'all' | 'any' | number,
  _,
  _,
>(
  inventorySearchCloudProviderList,
  inventorySearchQuery,
  (CloudProviders, query) => {
    const totalCloudProviders = CloudProviders.length;
    const totalSelectedCloudProviders = query.hosted_on_cloud_providers.length;

    if (!totalSelectedCloudProviders) {
      return 'any';
    } else if (
      totalSelectedCloudProviders &&
      totalCloudProviders === totalSelectedCloudProviders
    ) {
      return 'all';
    } else {
      return totalSelectedCloudProviders;
    }
  },
);

export const getServerLessCloudResourceTypes = createSelector<
  State,
  {},
  Array<string>,
  _,
  _,
>(inventorySearchCloudResourcesTypes, cloudResourcesTypes =>
  cloudResourcesTypes.ids
    .filter(({ category_enum }) => category_enum === 'SERVERLESS')
    .map(item => item.enum),
);
