//@flow
import React from 'react';
import { palette } from '@dt/theme';

import type { HostedOn } from '@dt/horizon-api';
import { HostedOnEnum } from '@dt/horizon-api';

// Logos and icons
import AzureLogo from './svg/azure_logo.svg';
import AwsLogo from './svg/aws_logo.svg';
import ApigeeLogo from './svg/apigee_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
import GcpLogo from './svg/gcp_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';
import KongLogo from './svg/kong_logo.svg';
import UnknownIcon from '@material-ui/icons/HelpOutline';

type Props = {| +hosted_on: string | HostedOn, +size?: number |};

const HostedOnIcon = ({ hosted_on, size = 14 }: Props) => {
  switch (hosted_on) {
    case HostedOnEnum.AMAZON_WEB_SERVICES:
      return <img height={size} width={size} src={AwsLogo} alt="AWS icon" />;
    case HostedOnEnum.GOOGLE_CLOUD_PLATFORM:
      return <img height={size} width={size} src={GcpLogo} alt="GCP icon" />;
    case HostedOnEnum.APIGEE:
      return (
        <img height={size} width={size} src={ApigeeLogo} alt="Apigee icon" />
      );
    case HostedOnEnum.AZURE:
      return (
        <img height={size} width={size} src={AzureLogo} alt="Azure icon" />
      );
    case HostedOnEnum.AXWAY:
      return (
        <img height={size} width={size} src={AxwayLogo} alt="Axway icon" />
      );
    case HostedOnEnum.MULESOFT:
      return (
        <img
          height={size}
          width={size}
          src={MulesoftLogo}
          alt="Mulesoft icon"
        />
      );
    case HostedOnEnum.KONG:
      return <img height={size} width={size} src={KongLogo} alt="Kong icon" />;
    case HostedOnEnum.DETECTION_FAILED:
    case HostedOnEnum.ON_PREMISE:
    case HostedOnEnum.UNKNOWN:
      return (
        <UnknownIcon
          style={{ height: size, width: size, color: palette.gray }}
        />
      );
    default:
      throw new Error(
        `Missing icon for ${hosted_on}, HostedOn is likely out of date`,
      );
  }
};

export default React.memo<Props>(HostedOnIcon);
