import { ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import React from 'react';
import { clearSessionAndRedirectToLogin, getInvariantUserAccount,
// @ts-ignore: #TODO-IMPLEMENT-DT-SESSION
 } from '@dt/session';
import ActivityIcon from '@material-ui/icons/History';
import AlertsIcon from '@material-ui/icons/Notifications';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { ExtLink } from '@dt/material-components';
import HelpIcon from '@material-ui/icons/HelpOutline';
import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
import NewReleaseIcon from '@material-ui/icons/NewReleases';
import PersonIcon from '@material-ui/icons/Person';
import PollIcon from '@material-ui/icons/Poll';
import ShareIcon from '@material-ui/icons/Share';
// @ts-ignore Ignore JS files
import UserRole from '@dt/enums/UserRoleEnum';
import UsersIcon from '@material-ui/icons/AccountCircle';
// @ts-ignore
import { useSession } from '@dt/ahura/src/session';
var SettingsMenu = function (_a) {
    var anchorEl = _a.anchorEl, handleClose = _a.handleClose;
    var loading = useSession({
        unauthenticatedRedirect: false,
    }).loading;
    if (loading)
        return null;
    var currentUser = getInvariantUserAccount().currentUser;
    return (React.createElement(Menu, { anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        } },
        React.createElement(ExtLink, { to: "/devsecops/v2/stanford-dish-security-university#whats-new", target: "_self", onClick: handleClose, "aria-label": "What's New Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(NewReleaseIcon, null)),
                "What's New")),
        currentUser.role === UserRole.MANAGER && (React.createElement(ExtLink, { to: "/management/v2/activity", target: "_self", onClick: handleClose, "aria-label": "Activity Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ActivityIcon, null)),
                "Activity"))),
        React.createElement(ExtLink, { to: currentUser.role === 'MANAGER' ? '/management/v2/users' : '/management/users/invite', target: "_self", onClick: handleClose, "aria-label": "Users Menu Item" },
            React.createElement(MenuItem, { disabled: !currentUser.can_invite_users },
                React.createElement(ListItemIcon, null,
                    React.createElement(UsersIcon, null)),
                currentUser.role === 'MANAGER' ? 'Manage ' : 'Invite ',
                "Users")),
        React.createElement(ExtLink, { to: "/management/v2/preferences", target: "_self", onClick: handleClose, "aria-label": "Preferences Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(AlertsIcon, null)),
                "Alerts")),
        React.createElement(ExtLink, { to: "/management/v2/shared-links", target: "_self", onClick: handleClose, "aria-label": "Shared Links Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(ShareIcon, null)),
                "Shared Links")),
        React.createElement(ExtLink, { to: "/management/v2/compliance-report", target: "_self", onClick: handleClose, "aria-label": "Compliance Report Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PollIcon, null)),
                "Compliance")),
        React.createElement(ExtLink, { to: "/management/v2/profile", target: "_self", onClick: handleClose, "aria-label": "Profile Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(PersonIcon, null)),
                "Profile")),
        React.createElement(ExtLink, { to: "https://support.securetheorem.com/", target: "_blank", onClick: handleClose, "aria-label": "Help and Support Menu Item" },
            React.createElement(MenuItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(HelpIcon, null)),
                "Help & Support")),
        React.createElement(MenuItem, { onClick: function () {
                clearSessionAndRedirectToLogin();
            }, "aria-label": "Sign Out Menu Item" },
            React.createElement(ListItemIcon, null,
                React.createElement(LogoutIcon, null)),
            "Sign out")));
};
export default SettingsMenu;
