// @flow
import { createAction } from 'redux-actions';

export const inventoryExportClicked = createAction<
  'inventory/export/start',
  [],
>('inventory/export/start');

export const inventoryExportCancelClicked = createAction<
  'inventory/export/cancel',
>('inventory/export/cancel');

export const inventoryExportResetSagaRequestsClicked = createAction<
  'inventory/export/refetch',
>('inventory/export/refetch');

export const inventoryExportRequestRow = createAction<
  'inventory/export/request',
  [string],
  { resource_type: string },
>('inventory/export/request', resource_type => ({
  resource_type,
}));

export const inventoryExportRestartRequestRow = createAction<
  'inventory/export/restart',
  [string, string],
  { resource_type: string, cursor: string },
>('inventory/export/restart', (resource_type, cursor) => ({
  resource_type,
  cursor,
}));

export const inventoryExportCompleted = createAction<
  'inventory/export/completed',
>('inventory/export/completed');
