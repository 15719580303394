// @flow

import type {
  ApiOperationsListQueryQuery,
  AssetTypeEnum,
  CloudResourcesListQuery,
  SpecialScanStatus,
} from '@dt/graphql-support/types';
import {
  AssetTypeEnumValues,
  HostedOnValues,
  HttpMethodValues,
  SpecialScanStatusValues,
} from '@dt/graphql-support/types';
import React, { memo, useMemo } from 'react';

import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TagHostedOn from '../TagHostedOn';
import Text from '../Text';
import ToolkitAssetTableLastTested from './ToolkitAssetTableLastTested';
import ToolkitTableLastScanStatus from './ToolkitAssetTableLastScanStatus';
import ToolkitTableSelectedRowScanProgress from './ToolkitAssetTableSelectedRowScanProgress';
import { Tooltip } from '@material-ui/core';
import format from 'date-fns/format';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  row: props => ({
    backgroundColor: props.backgroundColor,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.gray50,
    },
  }),
  cell: {
    padding: 8,
  },
  text: {
    maxWidth: 300,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  scanHistoryBlock: {
    borderRadius: 2,
    margin: 2,
    width: 14,
    minWidth: 14,
    maxWidth: 14,
    height: 14,
    minHeight: 14,
    maxHeight: 14,
    "&[data-color='red']": {
      background: palette.red30,
    },
    "&[data-color='green']": {
      background: palette.green30,
    },
  },
});

type CloudResourcesArrayType = $PropertyType<
  $PropertyType<CloudResourcesListQuery, 'cloud_resource_list'>,
  'cloud_resources',
>;

type ApiOperationsArrayType = $PropertyType<
  $PropertyType<ApiOperationsListQueryQuery, 'api_operation_list'>,
  'api_operations',
>;

type ScanType = {
  current_status: SpecialScanStatus,
  date_created: Date,
  ...
};

type Props = {
  asset: $ElementType<CloudResourcesArrayType, 0> | $ElementType<ApiOperationsArrayType, 0>,
  assetType: AssetTypeEnum,
  scans: $ReadOnlyArray<ScanType>,
  openRowId: ?string,
  onClick: ($ElementType<CloudResourcesArrayType, 0> | $ElementType<ApiOperationsArrayType, 0>) => void,
  portalPath: string,
  isV2?: boolean,
};

function getAssetDetails(asset) {
  // api operation asset
  if (asset.http_method) {
    return {
      id: asset.id,
      description: `API Operation at ${
        asset.http_method === HttpMethodValues.X_HORIZON_UNDEFINED_HTTP_METHOD ? 'ANY' : asset.http_method
      } ${asset.path || ''}`,
    };
  }
  //cloud resource asset
  return {
    id: asset.id,
    description: `${asset.asset_type_name || ''} at ${asset.name || ''}`,
  };
}

const assetTypeToPath: { [k: AssetTypeEnum]: string, ... } = Object.freeze({
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'network-services',
  [AssetTypeEnumValues.RESTFUL_API]: 'assets',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.API_OPERATION]: 'api-operations',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'assets',
  [AssetTypeEnumValues.GRPC_METHOD]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'assets',
  [AssetTypeEnumValues.SOAP_API]: 'assets',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'assets',
});

function ToolkitAssetTableRow({ asset, assetType, scans, openRowId, onClick, portalPath, isV2 = false }: Props) {
  const { id, description } = useMemo(() => getAssetDetails(asset), [asset]);

  const isRowOpen = Boolean(asset.id === openRowId);

  const classes = useStyles({
    backgroundColor: isRowOpen ? palette.gray50 : null,
  });

  const scanHistory = scans
    .slice(0, 5)
    .filter(scan => format(scan.date_created) >= format(asset.date_created))
    .map((scan: ScanType) => ({
      description: `
        ${format(scan.date_created, 'YYYY-MM-DD')} |
        ${'Protected'}
      `,
      color: 'green',
    }));

  let authScheme = '';
  if (asset.authentication_scheme) {
    authScheme = asset.authentication_scheme;
  }

  return (
    <TableRow classes={{ root: classes.row }} onClick={() => onClick(asset)}>
      <TableCell classes={{ root: classes.cell }}>
        <Grid container spacing={1} alignItems="center" alignContent="center">
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            {id && (
              <Grid container justify="center" alignItems="center" alignContent="center">
                <a
                  href={`${portalPath}/${isV2 ? 'v2/' : ''}${assetTypeToPath[assetType]}/${id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Text variant={'link'}>
                    <LaunchIcon />
                  </Text>
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cell }}>
        <Box display="flex" alignItems="center">
          <TagHostedOn hosted_on={asset.hosted_on || HostedOnValues.UNKNOWN} />
          <Text variant="body" component="div" className={classes.text}>
            {description}
          </Text>
        </Box>
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cell }}>
        <Grid container justify="flex-start">
          {scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING
            ? '-'
            : scanHistory?.map(({ description, color }, key) => (
                <Grid item key={key}>
                  <Tooltip title={description} aria-label={description}>
                    <div className={classes.scanHistoryBlock} data-color={color}></div>
                  </Tooltip>
                </Grid>
              ))}
        </Grid>
      </TableCell>

      <TableCell align="left" classes={{ root: classes.cell }}>
        <Text
          variant="code"
          style={{
            color: authScheme === 'Basic Auth' ? palette.red : 'initial',
          }}
        >
          {authScheme}
        </Text>
      </TableCell>

      <TableCell align="center" classes={{ root: classes.cell }}>
        <Box display="flex" justifyContent="center">
          {scans[scans.length - 1].current_status === SpecialScanStatusValues.FINISHED ? (
            <ToolkitTableLastScanStatus lastScanStatus={'Protected'} />
          ) : scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING ? (
            <ToolkitTableSelectedRowScanProgress offset={Math.floor(Math.random() * 20)} />
          ) : (
            '-'
          )}
          <ToolkitAssetTableLastTested
            lastScanStatus={'Protected'}
            discoveredDate={asset.date_created}
            lastScanDateCreated={scans[scans.length - 1].date_created}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default memo<Props>(ToolkitAssetTableRow);
