// @flow
import { type Saga } from 'redux-saga';
import { takeEvery, take } from 'redux-saga/effects';
import { type ActionType } from 'redux-actions';
import { policies, policyViolationStatus } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { Actions } from '@dt/notifications';

import {
  policyRuleCreate,
  policyRuleCreateError,
  policyRuleCreateSuccess,
  policyRuleDeleteFlowStart,
  policyRuleDeleteFlowStep,
  policyRuleDeleteFlowConfirm,
  policyRuleDeleteFlowComplete,
  policyRuleEdit,
  policyRuleViewViolationsDrawerMounted,
} from './actions';
import { all, put } from 'redux-saga/effects';
import { call } from 'redux-saga/effects';
import {
  takePatternAndFetch,
  handleNormalizedResponse,
} from '../resource_fetch/sagas';
import { getPolicyList } from '../policies/resource_fetch.sagas';
import { getPolicyViolationList } from '../policy_violations/resource_fetch.sagas';
import { select } from 'redux-saga/effects';
import { getDecoratedViolationsFromRuleId } from '../policy_violations/selectors';
import { getPolicyRuleTypeList } from '../policy_rule_types/resource_fetch.sagas';

function* createPolicyRule(
  action: ActionType<typeof policyRuleCreate>,
): Saga<void> {
  const { policy_id, params } = action.payload;
  try {
    const response = yield* callPromise(
      policies.policy_rules.create,
      policy_id,
      params,
    );

    if (response._type === 'error') {
      // TODO
      alert('Response error');
      throw new Error('response error');
    }

    yield call(handleNormalizedResponse, { policy_rules: [response.body] });

    yield put(policyRuleCreateSuccess(policy_id, params.policy_rule_type_id));
  } catch (error) {
    yield put(
      policyRuleCreateError(policy_id, params.policy_rule_type_id, error),
    );

    yield put(
      Actions.requestNotifyUser({
        text:
          'There was an error while processing your request to activate a policy rule. Please email support@datatheorem.com.',
      }),
    );
  }
}

function* policyRuleDeleteFlowSaga(
  action: ActionType<typeof policyRuleDeleteFlowStart>,
): Saga<void> {
  // Steps:
  // 1- Load all related policy violations
  // 2- Check if there is any unresolved policy violations.
  // 2.0 - Skip to 4 if no unresolved.
  // 2.1 - User review unresolved policy violations and add explanation
  // 3- Close all unresolved policy violations with explanation
  // 4- Delete Policy Rule - PolicyLoading
  // 5- Saga complete, user will close drawer to complete

  const { policy_rule } = action.payload;
  yield put(policyRuleDeleteFlowStep(policy_rule.id, 1));

  // Fetch related policy violations
  yield all([
    call(getPolicyViolationList, {
      violated_policy_rule_id: policy_rule.id,
    }),
  ]);

  // Select from state
  const related_unresolved_violations = yield select(
    getDecoratedViolationsFromRuleId,
    {
      policy_rule_id: policy_rule.id,
      status: policyViolationStatus.OPEN,
    },
  );

  let explanation: ?string;
  // there are unresolved policy violations
  if (related_unresolved_violations.length > 0) {
    yield put(policyRuleDeleteFlowStep(policy_rule.id, 2));
    // Wait to confirm
    const confirmAction: ActionType<
      typeof policyRuleDeleteFlowConfirm,
    > = yield take(policyRuleDeleteFlowConfirm.toString());
    explanation = confirmAction.payload.explanation;

    yield put(policyRuleDeleteFlowStep(policy_rule.id, 3));

    // close all policy violations with explanation
    // Backend will do this for now
    // yield all(
    //   related_unresolved_violations.map(violation =>
    //     call(policy_violations.patch, violation.id, {
    //       exception_type: PolicyViolationException.POLICY_RULE_DELETED,
    //       exception_explanation: userExplanation,
    //     }),
    //   ),
    // );
  }

  yield put(policyRuleDeleteFlowStep(policy_rule.id, 4));
  // send request
  yield* callPromise(
    policies.policy_rules.delete,
    policy_rule.belongs_to_policy_id,
    policy_rule.id,
    explanation ? { deletion_explanation: explanation } : {},
  );

  yield put(policyRuleDeleteFlowStep(policy_rule.id, 5));
  // Done.
  yield put(policyRuleDeleteFlowComplete(policy_rule.id));

  // Update data
  yield all([call(getPolicyViolationList, {}, { forceUpdate: true })]);
}

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      'mounted/policy_rules/config',
      function*(): Saga<void> {
        yield all([call(getPolicyList, {}), call(getPolicyRuleTypeList, {})]);
      },
    ),
    call(
      takePatternAndFetch,
      'policy_rules/view_violations/drawer/mounted',
      function*(
        action: ActionType<typeof policyRuleViewViolationsDrawerMounted>,
      ): Saga<void> {
        yield all([
          call(getPolicyViolationList, {
            violated_policy_rule_id: action.payload.policy_rule_id,
          }),
          call(getPolicyList, {}),
          call(getPolicyRuleTypeList, {}),
        ]);
      },
    ),
    takeEvery(policyRuleDeleteFlowStart.toString(), policyRuleDeleteFlowSaga),
    takeEvery(policyRuleCreate.toString(), createPolicyRule),
    takeEvery(policyRuleEdit.toString(), editPolicyRule),
  ]);
}

function* editPolicyRule(
  action: ActionType<typeof policyRuleEdit>,
): Saga<void> {
  const { policy_id, policy_rule_id, params } = action.payload;

  const response = yield* callPromise(
    policies.policy_rules.patch,
    policy_id,
    policy_rule_id,
    params,
  );

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
}
