// @flow
import { type Saga } from 'redux-saga';
import { all, call, takeEvery, put } from 'redux-saga/effects';
import { type ActionType } from 'redux-actions';
import { select } from '@dt/redux-saga-wrapped-effects';

import { apiOperationsReceived } from '../api_operations/actions';
import {
  getRestfulAPIDetails,
  getRestfulAPIsList,
} from './resource_fetch.sagas';
import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
  paginateWhile,
  paginateToEndLazy,
} from '../resource_fetch/sagas';
import {
  restfulAPIDetailsMounted,
  reachedEndOfRestfulApisList,
  reachedEndOfRestfulApisListForAssetSearchId,
  restfulAPIsReceived,
  restfulAPIsReceivedIdsForAssetSearch,
} from './actions';
import resourceType from './resourceType';
import { isUserViewingVisualization } from '../dashboard/saga-isViewingVisualization';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { inventory_search } from '../inventory/search/selectors';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(takePatternAndFetch, restfulAPIDetailsMounted.toString(), function*(
      action: ActionType<typeof restfulAPIDetailsMounted>,
    ): Saga<void> {
      yield call(getRestfulAPIDetails, action.payload.restfulAPIId);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getRestfulAPIsList, { cursor });
    }),
    takeEvery(restfulAPIsReceived.toString(), restfulAPIsReceivedSaga),
  ]);
}

export const getAllRestfulAPIsWhileOnVisualization = function*(): Saga<void> {
  yield call(
    paginateWhile,
    getRestfulAPIsList,
    resourceType,
    {},
    isUserViewingVisualization,
  );
};

export const getAllRestfulAPIsLazily = function*(params: {
  search_id?: string,
  cursor?: string,
}): Saga<void> {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getRestfulAPIsList,
      resourceType,
      { ...params },
      action =>
        reachedEndOfRestfulApisListForAssetSearchId.toString() &&
        params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getRestfulAPIsList,
      resourceType,
      { ...params },
      reachedEndOfRestfulApisList.toString(),
    );
  }
};

function* restfulAPIsReceivedSaga(
  action: ActionType<typeof restfulAPIsReceived>,
): Saga<void> {
  const payload = action.payload;

  if (payload.restful_apis) {
    const { lastQueryId, query } = yield* select(inventory_search, {});

    const collect = payload.restful_apis.reduce(
      (collect, api) => {
        // Collect asset group memberships of the restful api.
        if (api.asset_group_memberships) {
          collect.assetGroupMembers = collect.assetGroupMembers.concat(
            api.asset_group_memberships,
          );
        }

        // Collect api operations of the restful api.
        if (api.api_operations) {
          collect.api_operations = collect.api_operations.concat(
            api.api_operations,
          );
        }

        //collect API ids for current search result.
        collect.idsForCurrentSearch.push(api.id);
        return collect;
      },
      {
        assetGroupMembers: [],
        idsForCurrentSearch: [],
        api_operations: [],
      },
    );

    if (collect.assetGroupMembers) {
      yield put(assetGroupsMembershipReceived(collect.assetGroupMembers));
    }

    if (collect.api_operations) {
      yield put(apiOperationsReceived(collect.api_operations));
    }

    if (
      lastQueryId &&
      (!query.asset_types.length || query.asset_types.includes('RESTFUL_API'))
    ) {
      yield put(
        restfulAPIsReceivedIdsForAssetSearch(
          lastQueryId,
          collect.idsForCurrentSearch,
        ),
      );
    }
  }
}
