// @flow
import { type ActionType } from 'redux-actions';
import {
  specialScanRequestReceived,
  piiReportsInApiResponsesReceived,
} from './actions';
import type {
  SpecialScanRequest,
  PiiReportsInApiResponse,
} from '@dt/horizon-api';
import immer from 'immer';

export type SpecialScanRequestState = {|
  +id: { [string]: void | SpecialScanRequest, ... },
  +for_type: { [string]: void | string[], ... },
  +last_scan_for_type: { [string]: void | string, ... },
  +pii_reports_in_api_responses: {|
    +for_api_operation_id: { [string]: void | PiiReportsInApiResponse, ... },
  |},
|};

const initialState: SpecialScanRequestState = {
  id: {},
  for_type: {},
  last_scan_for_type: {},
  pii_reports_in_api_responses: {
    for_api_operation_id: {},
  },
};

type Actions = ActionType<typeof specialScanRequestReceived>;

export default immer<SpecialScanRequestState, Actions>(
  (draft, action: Actions): void | SpecialScanRequestState => {
    switch (action.type) {
      case specialScanRequestReceived.toString(): {
        action.payload.forEach(special_scan_request => {
          const { id, scan_type, date_created } = special_scan_request;
          draft.id[id] = special_scan_request;

          const for_type = (draft.for_type[scan_type] =
            draft.for_type[scan_type] || []);
          if (for_type && !for_type.includes(id)) {
            for_type.push(id);
          }

          const last_scan_for_type = (draft.last_scan_for_type[scan_type] =
            draft.last_scan_for_type[scan_type] || id);
          if (
            draft.id[last_scan_for_type] &&
            draft.id[last_scan_for_type].date_created < date_created
          ) {
            draft.last_scan_for_type[scan_type] = id;
          }
        });
        break;
      }

      case piiReportsInApiResponsesReceived.toString(): {
        const { pii_reports_in_api_responses } = action.payload;

        pii_reports_in_api_responses.forEach(pii_reports_in_api_response => {
          draft.pii_reports_in_api_responses.for_api_operation_id[
            pii_reports_in_api_response.api_operation_id
          ] = pii_reports_in_api_response;
        });
      }
    }
  },
  initialState,
);
