// @flow
import immer from 'immer';
import { type ActionType } from 'redux-actions';
import {
  webApplicationsReceived,
  webApplicationsReceivedIdsForAssetSearch,
} from './actions';
import type { WebApplication } from '@dt/horizon-api';

const initialState = {
  id: {},
  for_policy_violation_id: {},
  for_search_id: {},
};

export type WebApplicationState = {|
  id: { [string]: WebApplication | void, ... },
  for_policy_violation_id: { [string]: string | void, ... },
  for_search_id: { [string]: Array<string>, ... },
|};

type Actions =
  | ActionType<typeof webApplicationsReceivedIdsForAssetSearch>
  | ActionType<typeof webApplicationsReceived>;

export default immer<WebApplicationState, Actions>((draft, action: Actions) => {
  if (action.type === webApplicationsReceived.toString()) {
    for (const web_application of action.payload.web_applications) {
      draft.id[web_application.id] = web_application;
      for (const policy_violation_id of web_application.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = web_application.id;
      }
    }
  }

  if (action.type === webApplicationsReceivedIdsForAssetSearch.toString()) {
    const { searchId, ids } = action.payload;
    draft.for_search_id[searchId] = (
      draft.for_search_id[searchId] || []
    ).concat(ids);
  }
}, initialState);
