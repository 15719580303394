// @flow
import { createSelector } from 'reselect';
import { values } from '@dt/functions';
import {
  SpecialScanRequestTypeEnum,
  type SpecialScanRequest,
} from '@dt/horizon-api';
import { type State } from '../store_state_type';
import type { SpecialScanListType } from './type';

export const special_scan_requests = ({ special_scan_requests }: State) =>
  special_scan_requests;

export const pii_reports_in_api_responses = ({
  special_scan_requests: { pii_reports_in_api_responses },
}: State) => pii_reports_in_api_responses;

export const getLeakyAPIsScans = createSelector<
  State,
  { ... },
  SpecialScanListType,
  _,
  _,
  _,
  _,
  _,
>(special_scan_requests, special_scan_requests => {
  const scanRequestsForType =
    special_scan_requests.for_type[SpecialScanRequestTypeEnum.LEAKY_APIS] || [];
  return values(special_scan_requests.id).filter(scan =>
    scanRequestsForType.find(id => scan.id === id),
  );
});

export const getLastHackAndExtractScan = createSelector<
  State,
  { ... },
  'no-scan' | SpecialScanRequest,
  _,
  _,
  _,
  _,
  _,
>(special_scan_requests, special_scan_requests => {
  const lastScanId =
    special_scan_requests.last_scan_for_type[
      SpecialScanRequestTypeEnum.HACK_AND_EXTRACT
    ];
  return (
    values(special_scan_requests.id).find(scan => scan.id === lastScanId) ||
    'no-scan'
  );
});

export const getLastLeakyAPIsScan = createSelector<
  State,
  { ... },
  'no-scan' | SpecialScanRequest,
  _,
  _,
  _,
  _,
  _,
>(special_scan_requests, special_scan_requests => {
  const lastScanId =
    special_scan_requests.last_scan_for_type[
      SpecialScanRequestTypeEnum.LEAKY_APIS
    ];
  return (
    values(special_scan_requests.id).find(scan => scan.id === lastScanId) ||
    'no-scan'
  );
});

export const getLastDetectInjectScan = createSelector<
  State,
  { ... },
  'no-scan' | SpecialScanRequest,
  _,
  _,
  _,
  _,
  _,
>(special_scan_requests, special_scan_requests => {
  const lastScanId =
    special_scan_requests.last_scan_for_type[
      SpecialScanRequestTypeEnum.DETECT_AND_INJECT
    ];
  return (
    values(special_scan_requests.id).find(scan => scan.id === lastScanId) ||
    'no-scan'
  );
});
