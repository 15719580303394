//@flow
import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { result, check } from './util';
import type { JiraExportFilterEnum } from '@dt/enums/JiraExportFilterEnum';

/**
 * Every customer has the ID number 9 saved for only a global jira config
 * We can hardcode that right here.
 */
export const GLOBAL_JIRA_CONFIG_ID = '9';

/**
 * Helper method for transitioning a Jira config response to a Jira Config Request
 */

export const jiraConfigToRequest = (
  jiraConfig: JiraIntegrationConfig,
  isGlobal: boolean,
  isPatch: boolean,
): JiraIntegrationConfigRequest => {
  const sfc = jiraConfig.severity_field_config
    ? { data: jiraConfig.severity_field_config }
    : undefined;
  const staticFields = jiraConfig.static_fields
    ? { data: jiraConfig.static_fields }
    : undefined;
  const dynamicFields = jiraConfig.dynamic_fields
    ? { data: jiraConfig.dynamic_fields }
    : undefined;

  const base: JiraIntegrationConfigRequest = {
    base_url: jiraConfig.base_url,
    username: jiraConfig.username,
    password: jiraConfig.password ? jiraConfig.password : undefined,
    project_key_or_id: jiraConfig.project_key_or_id,
    type_of_issue_name: jiraConfig.type_of_issue_name,
    export_pre_prod: jiraConfig.export_pre_prod,
    export_prod: jiraConfig.export_prod,
    export_filter: jiraConfig.export_filter,
    severity_field_config: sfc,
    static_fields: staticFields,
    dynamic_fields: dynamicFields,
  };

  // Due to how the backend is implemented, we can't have is_global set to false when patching it
  // So we simply just remove the field when we're in the global config and patching it
  if (isGlobal && !isPatch) base.is_global = isGlobal;

  // When global, we only need the minimum requirements, ignore everything else
  if (isGlobal) return base;

  return {
    ...base,
    base_id: jiraConfig.base_id ? jiraConfig.base_id : undefined,
    raw_mobile_app_id: jiraConfig.raw_mobile_app_id
      ? parseInt(jiraConfig.raw_mobile_app_id, 10)
      : undefined,
    mobile_app_id: jiraConfig.mobile_app_id
      ? jiraConfig.mobile_app_id
      : undefined,
  };
};

export type SeverityFieldConfigMessage = {
  data: {
    field_id: string,
    high_severity_value_id: string,
    medium_severity_value_id: string,
    low_severity_value_id: string,
    ...
  },
  ...
};

export type StaticFieldsMessage = {
  data: $ReadOnlyArray<{
    field_id: string,
    field_value: string,
    ...
  }>,
  ...
};

export type DynamicFieldsMessage = {
  data: $ReadOnlyArray<{
    field_id: string,
    value: string,
    ...
  }>,
  ...
};

export type JiraIntegrationConfigRequest = {
  base_url?: string,
  username?: string,
  // Only when updating/creating jira integrations
  password?: string,
  project_key_or_id?: string,
  type_of_issue_name?: string,
  export_pre_prod?: boolean,
  export_prod?: boolean,
  export_filter?: JiraExportFilterEnum,
  is_global?: boolean,
  severity_field_config?: SeverityFieldConfigMessage,
  static_fields?: StaticFieldsMessage,
  dynamic_fields?: DynamicFieldsMessage,
  // When creating a clone from another configuration, we need to know the ID of that config so we can 'clone' over the properties
  base_id?: number,
  // Raw mobile app ID needed if it's not a global integration config
  raw_mobile_app_id?: number,
  // If cloning, which mobile app id should the new clone point to?
  mobile_app_id?: number,
  ...
};

export type JiraIntegrationConfigResponse = {
  base_url: string,
  username: string,
  type_of_issue_name: string,
  export_filter: JiraExportFilterEnum,
  project_key_or_id: string,
  export_pre_prod: boolean,
  export_prod: boolean,
  id: string,
  is_global: boolean,
  raw_mobile_app_id?: string,
  severity_field_config?: {
    field_id: string,
    high_severity_value_id: string,
    medium_severity_value_id: string,
    low_severity_value_id: string,
    ...
  },
  static_fields?: $ReadOnlyArray<{
    field_id: string,
    field_value: string,
    ...
  }>,
  dynamic_fields?: $ReadOnlyArray<{
    field_id: string,
    value: string,
    ...
  }>,
  ...
};

/**
 * Type to be used in the jira forms. There is a helper function to transform data into
 * what the server accepts before sending it off
 */
export type JiraIntegrationConfig = JiraIntegrationConfigResponse & {
  // Password not given when server sends a response
  password?: string,
  // ID to jira config we're updating, or if creating what mobile app jira config points to
  id?: string,
  // If cloning, what id do we base it off?
  base_id?: number,
  // Needed if it's not a global jira config
  raw_mobile_app_id?: string,
  // Same, if not a global jira config
  mobile_app_id?: number,
  ...
};

export async function get(id: number): Promise<JiraIntegrationConfigResponse> {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`,
  )
    .then(parse)
    .then(result);
}

export async function list(): Promise<
  $ReadOnlyArray<JiraIntegrationConfigResponse>,
> {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/all`)
    .then(parse)
    .then(result);
}

export async function del(id: string): Promise<void> {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`,
    {
      method: 'DELETE',
    },
  )
    .then(parse)
    .then(check);
}

export async function create(
  jiraConfig: JiraIntegrationConfigRequest,
): Promise<JiraIntegrationConfigResponse> {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira`, {
    method: 'POST',
    body: JSON.stringify(jiraConfig),
  })
    .then(parse)
    .then(result);
}

export async function patch(
  jiraConfig: JiraIntegrationConfigRequest,
  id: number,
): Promise<JiraIntegrationConfigResponse> {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`,
    {
      method: 'PATCH',
      body: JSON.stringify(jiraConfig),
    },
  )
    .then(parse)
    .then(result);
}
