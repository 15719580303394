// @flow
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import { values } from '@dt/functions';
import { decorate, restfulApis } from './util';
import { openapi_definitions } from '../openapi_definitions/selectors';
import type { RestfulAPIDecoratedList, RestfulAPIDecorated } from './types';
import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import type {
  APIOperationsDecoratedList,
  APIOperationDecorated,
} from '../api_operations/types';
import { getAllNetworkServices } from '../network_services/selectors';
import type { State } from '../store_state_type';
import {
  getCurrentSearchId,
  getResultsOfSearch,
} from '../inventory/search/selectors';

const idFromProps = (_: State, props: { id?: ?string, ... } = {}) => props.id;

export const getRestfulAPIFromId = createSelector<
  State,
  { id?: ?string, ... },
  ?RestfulAPIDecorated,
  _,
  _,
  _,
  _,
  _,
>(
  restfulApis,
  idFromProps,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (
    apis,
    id,
    openapi_definitions,
    network_services_decorated,
    policy_violations_decorated,
  ) => {
    if (!id) {
      throw new Error('id is required for getRestfulAPIFromId selector');
    }

    const api = apis.id[id];

    if (!api) {
      return null;
    }

    return decorate(
      api,
      network_services_decorated,
      values(openapi_definitions.id),
      policy_violations_decorated,
    );
  },
);

export const getRestfulAPIsObj = createObjectSelector<
  State,
  { ... },
  ?RestfulAPIDecorated,
  _,
  _,
  _,
  _,
>(
  state => state.restful_apis.id,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (
    api,
    openapi_definitions,
    network_services_decorated,
    policy_violations_decorated,
  ) =>
    decorate(
      api,
      network_services_decorated,
      values(openapi_definitions.id),
      policy_violations_decorated,
    ),
);

export const getRestfulAPIs = createSelector<
  State,
  { ... },
  RestfulAPIDecoratedList,
  _,
  _,
  _,
  _,
>(
  getRestfulAPIsObj,

  apis => values(apis).filter(Boolean),
);

export const getRestfulAPIsForInventory = createSelector<
  State,
  { ... },
  RestfulAPIDecoratedList,
  _,
  _,
  _,
  _,
  _,
>(
  restfulApis,
  getCurrentSearchId,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (
    restful_apis,
    searchId,
    openapi_definitions,
    network_services_decorated,
    policy_violations_decorated,
  ) => {
    const apis = getResultsOfSearch(searchId, restful_apis);
    return values(apis)
      .map(api =>
        decorate(
          api,
          network_services_decorated,
          values(openapi_definitions.id),
          policy_violations_decorated,
        ),
      )
      .filter(Boolean);
  },
);

export const getAllApiOperations = createSelector<
  State,
  { ... },
  APIOperationsDecoratedList,
  _,
  _,
  _,
  _,
>(
  getRestfulAPIs,

  getRestfulAPIs => {
    const restful_apis_decorated = getRestfulAPIs;
    return restful_apis_decorated
      .flatMap(
        restful_api_decoreated =>
          restful_api_decoreated.api_operations_decorated,
      )
      .filter(Boolean);
  },
);

export const getRestfulApiFromApiOperationId = createSelector<
  State,
  { id: string, ... },
  ?APIOperationDecorated,
  _,
  _,
  _,
  _,
>(
  getAllApiOperations,
  (_: State, props: { id: string, ... } = {}) => props.id,

  (getAllApiOperations, idFromProps) => {
    const api_operations_decorated = getAllApiOperations;
    const id = idFromProps;

    return api_operations_decorated.find(
      api_operation_decorated => api_operation_decorated.id === id,
    );
  },
);
