// @flow
import { type Saga } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import { type ActionType } from 'redux-actions';
import { policyViolationDetailsMounted } from './actions';

import {
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
} from '../resource_fetch/sagas';
import {
  getPolicyViolationDetails,
  getPolicyViolationList,
} from './resource_fetch.sagas';
import resourceType from './resourceType';

export default function* watchForResourceFetching(): Saga<void> {
  yield all([
    call(
      takePatternAndFetch,
      'mounted/policy_violations/*'.toString(),
      function*(): Saga<void> {
        yield all([call(getPolicyViolationList, {})]);
      },
    ),
    call(
      takePatternAndFetch,
      policyViolationDetailsMounted.toString(),
      function*(
        action: ActionType<typeof policyViolationDetailsMounted>,
      ): Saga<void> {
        const policyViolationId = action.payload.policyViolationId;
        yield all([call(getPolicyViolationDetails, policyViolationId)]);
      },
    ),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function*({
      cursor,
    }): Saga<void> {
      yield call(getPolicyViolationList, { cursor });
    }),
  ]);
}
