// @flow
import { put, call } from 'redux-saga/effects';
import { ip_ranges } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { withCache, handleNormalizedResponse } from './../resource_fetch/sagas';
import resourceType from './resourceType';

import type { Saga } from 'redux-saga';

export const getIpRangesList = withCache<{ ... }>(
  'ip_ranges_list',
  function* getIpRanges(params): Saga<void> {
    yield put(paginationBeginRequest(resourceType, params));

    const response = yield* callPromise(ip_ranges.list, params);
    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);

    if (response.body.pagination_information) {
      yield put(
        paginationEndRequest(
          resourceType,
          params,
          response.body.pagination_information,
        ),
      );
    }
  },
);
