// @flow
import { createAction } from 'redux-actions';
import {
  type AssetGroupMembershipList,
  type AssetGroupMembershipParams,
} from '@dt/horizon-api';

export const assetGroupsMembershipReceived = createAction<
  'asset_groups/membership/received',
  [AssetGroupMembershipList],
  AssetGroupMembershipList,
>(
  'asset_groups/membership/received',
  asset_group_memberships => asset_group_memberships,
);

export const assetGroupsMembershipMounted = createAction<
  'asset_groups/membership/mounted',
  [string],
  {| key: 'asset_groups_membership', assetGroupId: string |},
>('asset_groups/membership/mounted', id => ({
  key: 'asset_groups_membership',
  assetGroupId: id,
}));

export const assetGroupMembershipCreate = createAction<
  'asset_groups/membership/create',
  [
    {
      assetGroupId: string,
      assets: AssetGroupMembershipParams,
      ...
    },
  ],
  {
    assetGroupId: string,
    assets: AssetGroupMembershipParams,
    ...
  },
>('asset_groups/membership/create', params => params);
