//@flow
import { createAction } from 'redux-actions';
import { type SecurityFinding, type TargetStatus } from '@dt/findings/types'; //eslint-disable-line no-unused-vars

import { type FindingPriorityEnum as FindingPriorityEnumType } from '@dt/enums/FindingPriorityEnum'; //eslint-disable-line no-unused-vars
import type { FindingTargetStatusEnum } from '@dt/enums/FindingTargetStatusEnum';
import type { Application } from '@dt/user-api/mobile_apps';
import { createRoutine } from 'redux-saga-routines';

export const updateStatusStarted = createAction<
  'UPDATE_STATUS_STARTED',
  [string, string],
  {
    findingId: string,
    targetId: string,
    ...
  },
>('UPDATE_STATUS_STARTED', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusFailure = createAction<
  'UPDATE_STATUS_FAILURE',
  [string, string],
  {
    findingId: string,
    targetId: string,
    ...
  },
>('UPDATE_STATUS_FAILURE', (findingId, targetId) => ({ findingId, targetId }));

export const updateStatusSuccess = createAction<
  'UPDATE_STATUS_SUCCESS',
  [string, string, TargetStatus],
  {
    findingId: string,
    status: TargetStatus,
    targetId: string,
    ...
  },
>('UPDATE_STATUS_SUCCESS', (findingId, targetId, status) => ({
  findingId,
  status,
  targetId,
}));

export const updateStatus = createAction<
  'UPDATE_STATUS',
  [string, string, FindingTargetStatusEnum],
  {
    securityFindingId: string,
    targetId: string,
    newStatus: FindingTargetStatusEnum,
    ...
  },
>('UPDATE_STATUS', (securityFindingId, targetId, newStatus) => ({
  securityFindingId,
  targetId,
  newStatus,
}));

export const changePriority = createAction<
  'CHANGE_PRIORITY',
  [FindingPriorityEnumType, ?SecurityFinding],
  {
    priority: FindingPriorityEnumType,
    finding: ?SecurityFinding,
    ...
  },
>('CHANGE_PRIORITY', (priority, finding) => ({
  priority,
  finding,
}));

export const securityFindingOpened = createAction<
  'SECURITY_FINDING_OPENED',
  [SecurityFinding, ?$ReadOnlyArray<Application>],
  {
    finding: SecurityFinding,
    linkedApps?: ?$ReadOnlyArray<Application>,
  },
>('SECURITY_FINDING_OPENED', (finding, linkedApps) => ({
  finding,
  linkedApps,
}));

export const updatePermanentlyClosedStatus = createAction<
  'UPDATE_PERMANENTLY_CLOSED_STATUS',
  [SecurityFinding, ?boolean, ?FindingTargetStatusEnum],
  {
    finding: SecurityFinding,
    isPermanentlyClosed: ?boolean,
    requestedAggregatedStatus: ?FindingTargetStatusEnum,
    ...
  },
>(
  'UPDATE_PERMANENTLY_CLOSED_STATUS',
  (finding, isPermanentlyClosed, requestedAggregatedStatus) => ({
    finding,
    isPermanentlyClosed,
    requestedAggregatedStatus,
  }),
);

export const securityFindingsFetchAllStart = createAction<
  'SECURITY_FINDINGS_FETCH_ALL_START',
  [],
  void,
>('SECURITY_FINDINGS_FETCH_ALL_START');

export const securityFindingsFetchAllFinish = createAction<
  'SECURITY_FINDINGS_FETCH_ALL_FINISH',
  [],
  void,
>('SECURITY_FINDINGS_FETCH_ALL_FINISH');

type LinkedIssuesRoutineTriggerParams = {
  finding: SecurityFinding,
  linkedApps: $ReadOnlyArray<Application>,
  ...
};
type LinkedIssuesRoutineRequestParams = {
  mobileAppId?: string,
  issueTypeId?: string,
  ...
};
type LinkedIssuesRoutineResponseParams = {
  linkedFindings?: $ReadOnlyArray<SecurityFinding>,
  ...
};
type LinkedIssuesRoutineSuccess = {
  mobileAppId?: string,
  issueTypeId?: string,
  ...
};

type LinkedIssuesRoutineError = string;

export const linkedIssuesRoutine = createRoutine<
  LinkedIssuesRoutineTriggerParams,
  LinkedIssuesRoutineRequestParams,
  LinkedIssuesRoutineSuccess,
  LinkedIssuesRoutineError,
  LinkedIssuesRoutineResponseParams,
>('LINKED_ISSUES');

export const securityFindingLightboxOpened = createAction<
  'SECURITY_FINDING_LIGHTBOX_OPENED',
  [string],
  string,
>('SECURITY_FINDING_LIGHTBOX_OPENED', findingId => findingId);
